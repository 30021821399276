import trivialRedux from 'trivial-redux'
import humps from 'humps'
import axios from 'axios'

export const axiosInstance = axios.create({})

function transformRequest(config) {
    if (config.data instanceof FormData) return config
    if (config.data) {
        config.data = humps.decamelizeKeys(config.data)
    }
    if (config.params) {
        config.params = humps.decamelizeKeys(config.params)
    }
    return config
}

function camelizeResponse(response) {
    let { data } = response
    if (data instanceof Blob) return response
    data = humps.camelizeKeys(data)
    if (!data) return response
    if (data.data) {
        response.data = data.data
        return response
    }
    if (data.error) {
        response.data = data.error
        return response
    }
    response.data = data
    return response
}

axiosInstance.interceptors.response.use(
    response => {
        return camelizeResponse(response)
    },
    error => {
        error.response = camelizeResponse(error.response)
        if (error.response.data.error) {
            error.response.data = error.response.data.error
        }
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.request.use(config => {
    return transformRequest(config)
})

export const setAuthorizationData = (token, accountId) => {
  axiosInstance.defaults.headers.common.Authorization = token;
  axiosInstance.defaults.headers.common.Account = accountId;
}

const config = {
  skipFormat: true,
  extra: {
    camelizeKeys: true
  }
}

export default function(endpoints){
  return trivialRedux(endpoints, config)
}
