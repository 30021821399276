import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './store';
import PaymentsContainer from './containers';
import {NotificationContainer} from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../shared/mui-theme'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import RollbarBoundary from "@/shared/components/rollbar-boundary";

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const PaymentsApp = (props) => (
    <RollbarBoundary>
      <Provider store={configureStore(props.storeInitialState)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={muiTheme}>
            <React.Fragment>
              <PaymentsContainer />
              <NotificationContainer/>
            </React.Fragment>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </RollbarBoundary>
);

export default PaymentsApp;
