import baseEndpoints from '../../BaseApp/endpoints'

const FETCH = (entry) => (
  {
    type: 'fetch',
    entry
  }
)

export default {
  ...baseEndpoints,
  payments: '~/api/v1/account/payments',
  accounts: '~/api/v1/accounts',
  shops: '~/api/v1/account/shops',
  stats: FETCH('~/api/v1/account/statistics'),
  paymentCount: FETCH('~/api/v1/account/payments/count')
}
