import React from 'react'
import OnPrepare from '../components/on-prepare'
import YandexOnPrepare from "../components/yandex-on-prepare"
import ConfigureYandexTerminal from "@/ShopApp/components/configure-yandex-terminal"
import Waiting from '../components/waiting'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import api from '../api'
import {BID_STATUSES} from '../../shared/utils/global-values'
import cnames from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from "@material-ui/core/Typography"
import YandexPayBid from "@/ShopApp/components/yandex-pay-bid"

STEPS = ['Собираем данные', 'Проверяем заявку', 'Настройка сервиса', 'Заявка одобрена']

TEXT_STEP_1 = "Заявка принята в обработку. Нам понадобится 1-3 рабочих дня на обработку ваших данных. Уведомление об изменении статуса заявки вы получите на почту указанную при регистрации"
TEXT_STEP_2 = "Почти все готово, теперь осталось выпустить сертификаты для платежных систем. Это займет не более 1 рабочего дня)"
TEXT_STEP_3 = "Заявка обработана! Теперь можно проводить платежи по картам"

updateBid = (id, data) =>
  req = api.requests.bids.update(id, data)
  req.camelizeKeys = false
  req

class BidContainer extends React.Component

  constructor: ->
    super()
    @state =
      commentsOpened: false

  componentDidMount: ->
    @props.getBid(@props.bidId)

  updateBid: (data) =>
    try
      await @props.updateBid(@props.bidId, data)
      await @props.getBid(@props.bidId)
    catch exc
      console.error(exc);

  findStep: =>
    BID_STATUSES.findIndex((el) => el == @props.bid.status)

  textByStep: (step) =>
    switch step
      when 1
        TEXT_STEP_1
      when 2
        TEXT_STEP_2
      when 3
        TEXT_STEP_3

  renderComment:(comment, index) =>
    { classes } = @props
    textStyles = [classes.text]
    textStyles.push(classes.textInactive) if comment.resolved
    <div key={index} style={borderTop: '1px solid #dddfe0'}>
      <p className={cnames(...textStyles)}>
        {comment.comment}
      </p>
    </div>

  renderUbrrBid: () =>
    { bid, legalType } = @props
    activeStep = @findStep() if bid?
    <React.Fragment>
      { activeStep is 0 && <OnPrepare bid={bid} updateBid={@updateBid} legalType={legalType}/>}
      { activeStep is 1 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
      { activeStep is 2 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
      { activeStep is 3 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
    </React.Fragment>

  renderYandexBid: () =>
    { bid, legalType } = @props
    activeStep = @findStep() if bid?
    <React.Fragment>
      { activeStep is 0 && <YandexOnPrepare bid={bid} updateBid={@updateBid} legalType={legalType}/>}
      { activeStep is 1 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
      { activeStep is 2 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
      { activeStep is 3 && <Waiting bid={bid} text={@textByStep(activeStep)} legalType={legalType}/>}
    </React.Fragment>

  render: ->
    { classes, bid } = @props
    return (
      <div>
        <LinearProgress/>
      </div>
    ) if !bid

    return <YandexPayBid /> if bid.type == "AccountBid::YandexPay"

    activeStep = @findStep() if bid?
    <div>
      <div className={classes.container}>
        <Typography className={classes.headerText} variant={"h2"}>Заявка на подключение</Typography>
        <Stepper activeStep={activeStep} alternativeLabel={true}>
         {
           STEPS.map((step) =>
            <Step key={step}>
              <StepLabel>
                {step}
              </StepLabel>
            </Step>
           )
         }
        </Stepper>
        { @renderUbrrBid() }
      </div>
      <div className={classes.container} style={marginTop:12}>
        {bid.comments.map(@renderComment)}
      </div>
    </div>


inlineStyles = {
  container: {
    marginLeft: '10%',
    marginRight: '10%'
  }
}

styles = (theme) => (
  container:
    backgroundColor: 'white'
  headerText:
    padding: theme.spacing(2, 2)
  text:
    padding: 24
    fontSize: 18
    fontWeight: 300
  textInactive:
    color: 'gray'
    textDecoration: 'line-through'
)

mapStateToProps = (state) => ({
  userName: state.userName,
  bidId: state.currentBidId,
  bid: state.bids.data.current,
  legalType: state.accountLegalType
})

mapDispatchToProps = {
  getBid: api.actions.bids.show,
  updateBid: updateBid
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BidContainer))
