import React from 'react'
import {connect} from 'react-redux'
import api from '../api'
import HeaderAppBar from '../../shared/components/HeaderBar'
import { withStyles } from '@material-ui/core/styles';
import OperationsList from '../components/OperationsList'
import blue from '@material-ui/core/colors/blue'
import moment from 'moment'
import PaymentCharts from '../components/PaymentCharts'


class PaymentsContainer extends React.Component {

  state = {
    periodFrom: moment().startOf('month'),
    periodTo: moment(),
    shopId: null,
  }

  componentDidMount(){
    this.reload()
  }

  get lastUsedShopId() {
    const strId = localStorage.getItem('lastShopId');
    if (!strId) {
      return null;
    }

    return parseInt(strId);
  }

  reload = async () => {
    const {data} = await this.props.getShops();
    if (data && data.length) {
      const shop = data.find(el => el.id == this.lastUsedShopId) || data[0];
      this.setState({shopId: shop.id})
    }
  }

  periodChanged = (periodObject) => {
    this.setState(periodObject)
  }

  goToRoot = () => {
    window.location.assign(`/accounts/${this.props.accountId}/payments`)
  }

  changeShop = (shop) => {
    localStorage.setItem('lastShopId', shop.id);
    this.setState({shopId: shop.id})
  }

  render(){
    const {classes, shops, getPayments, userName} = this.props
    const {shopId} = this.state;

    return(
      <div style={inlineStyles.container}>
        <HeaderAppBar
          mainScreenLink={this.goToRoot}
          currentShopId={shopId}
          shopsList={shops}
          onShopChanged={this.changeShop}
        />
        <PaymentCharts periodFrom={this.state.periodFrom} periodTo={this.state.periodTo} shopId={shopId}/>
        <OperationsList periodChanged={this.periodChanged} shopId={shopId}/>
      </div>
    )
  }
}

const inlineStyles = {
  container: {
    margin: "auto",
    maxWidth: "1200px",
    padding: "0 16px",
  }
}

const styles = theme => ({
  headingText: {
    color: '#9299a2',
    fontWeight: 200,
    fontSize: 20,
    lineHeight: '12px',
    letterSpacing: '1px',
  },
  shopTitleText: {
    color: '#333',
    fontWeight: 300,
    fontSize: 25,
    lineHeight: '12px',
    letterSpacing: '1px'
  },
  paper: {
    borderRadius: 5
  },
  badge: {
    right: -25
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  column: {
    flexBasis: '33.33%',
  },
  shopsList: {
    backgroundImage: `linear-gradient(to top,${blue[500]} 0,#7196ba 100%)`,
    padding: '30px 30px 30px 0px',
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'scroll'
  }
})

const mapStateToProps = state => ({
  shops: state.shops.data.collection,
  userName: state.userName,
  accountId: state.accountId
})

const mapDispatchToProps = {
  getShops: api.actions.shops.index,
  logout: api.actions.sessions.destroy
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentsContainer))
