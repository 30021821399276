import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import lightBlue from '@material-ui/core/colors/lightBlue'
import grey from '@material-ui/core/colors/lightBlue'
import green from '@material-ui/core/colors/green'
import deepOrange from '@material-ui/core/colors/deepOrange'
import Icon from '@material-ui/core/Icon'
import Expand from 'react-expand-animated'
import moment from 'moment'
import {
  APPROVED
} from '../../shared/utils/global-values'
import ColoredButton from '../../shared/components/button'
import Tooltip from '@material-ui/core/Tooltip'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import checker from '../../shared/utils/permissions'
import {NAME_MAPPER} from '../../shared/utils/global-values'
import { CardInfo } from 'card-info'
import Typography from "@material-ui/core/Typography";

const COLOR_MAPPER = {
  'PRE_CREATED': grey[600],
  'CREATED': lightBlue[800],
  'ON-CHECKOUT': lightBlue[800],
  'ON-PAYMENT': lightBlue[800],
  'APPROVED': green[800],
  'DECLINED': deepOrange[500],
  'CANCELED': deepOrange[500],
  'REVERSED': grey[900],
  'REFUNDED': grey[900]
}

class PaymentItem extends React.Component {

  state = {
    hovered: false,
    opened: false
  }

  changeHovered = (hovered) => {
    this.setState({hovered})
  }

  changeOpened = () => {
    this.setState({opened: !this.state.opened})
  }

  clientInfo(client){
    return client.email
  }

  approvedPayment = () => {
    const {payment} = this.props
    return payment.status === APPROVED
  }

  get formattedCardNumber() {
    const {cardPan} = this.props.payment;
    return `${cardPan.slice(0, 4)} ${cardPan.slice(4, 8)} ${cardPan.slice(8, 12)} ${cardPan.slice(12, cardPan.length)}`
  }

  get cardBrand() {
    return CardInfo.getBrands().find((el) => [el.name, el.alias].includes(this.props.payment.cardBrand))
  }

  render(){
    const {classes, payment} = this.props
    const {hovered, opened} = this.state
    const date = moment(payment.createdAt)
    return(
      <div className={classes.container}>
      <div className={classes.rowContainer}
           onMouseOver={this.changeHovered.bind(this, true)}
           onMouseOut={this.changeHovered.bind(this, false)}
           onClick={this.changeOpened}>
        <div className={classes.metaDataContainer}>
          <Tooltip title={NAME_MAPPER[payment.status] || payment.status_text}>
            <div className={classes.iconContainer} style={{ background: COLOR_MAPPER[payment.status] || grey[900]}}>
              <Icon fontSize={'inherit'}>credit_card</Icon>
            </div>
          </Tooltip>
        </div>
        <div className={classes.dataContainer}>
          <div>
            <p className={classes.secondaryText}>{payment.fromShop}</p>
            <p className={classes.primaryText}>{payment.client.email}</p>
          </div>
          <div>
            <div>
              <span className={classes.primaryText}>{payment.amount}</span>
            </div>
            <span className={classes.secondaryText}>Оплата картой</span>
          </div>
        </div>
      </div>
      <Expand open={opened}>
        <div className={classes.infoContainer}>
          <Typography variant={"body2"}>Детали платежа</Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Статус оплаты</span>
            <span style={{marginLeft: 12}} className={classes.primarySmallText}>{NAME_MAPPER[payment.status] || payment.status_text}</span>
          </Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Сумма</span>
            <span style={{marginLeft: 12}} className={classes.primarySmallText}>{payment.amount}</span>
          </Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Описание</span>
            <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{payment.description}</span>
          </Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Имя клиента</span>
            <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{payment.client.fullName}</span>
          </Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Телефон клиента</span>
            <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{payment.client.phone}</span>
          </Typography>
          <Typography variant={"body1"}>
            <span className={classes.secondaryText}>Внешний номер заказа</span>
            <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{payment.externalNumber || 'Нет'}</span>
          </Typography>
          {
            payment.transactionTime && (
              <Typography variant={"body1"}>
                <span className={classes.secondaryText}>Время оплаты</span>
                <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{moment(payment.transactionTime).format('DD MMM HH:mm')}</span>
              </Typography>
            )
          }
          {
            payment.refundTime && (
              <Typography variant={"body1"}>
                <span className={classes.secondaryText}>Время возврата</span>
                <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{moment(payment.refundTime).format('DD MMM HH:mm')}</span>
              </Typography>
            )
          }
          {payment.cardPan && (
            <Typography variant={"body1"}>
              <span className={classes.secondaryText}>Номер карты</span>
              <span style={{marginLeft: 12}} className={classes.primarySmallText}>{this.formattedCardNumber}</span>
              {this.cardBrand && <img src={this.cardBrand.logoBlack} className={classes.brandLogo} />}
            </Typography>
          )}
          <Tooltip title={'RRN - номер по которому можно отследить состояние платежа'}>
            <Typography variant={"body1"}>
              <span className={classes.secondaryText}>RRN</span>
              <span style={{marginLeft: 12}}  className={classes.primarySmallText}>{payment.rrn}</span>
            </Typography>
          </Tooltip>
          {
            this.approvedPayment() && this.props.canCancelPayment && (
              <div style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems: 'flex-start', marginTop: 12}}>
                <span className={classes.secondaryText}>Возврат средств происходит в течение 3-х рабочих дней.</span>
                <div style={{marginTop: 12}}>
                  <ColoredButton variant='contained' placeholder='Сделать возврат' onClick={this.props.cancelOrder}/>
                </div>
              </div>
            )
          }
        </div>
      </Expand>
      </div>
    )
  }
}

PaymentItem.defaultProps = {
  payment: {}
}

const styles = theme => ({
  container: {
    cursor: 'pointer',
    background: 'white',
    '&:hover':{
      background: '#F6F7F8'
    }
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #dddfe0',
    padding: '20px 16px 14px',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(2),
    }
  },
  iconContainer: {
    borderRadius: '64px',
    color: 'white',
    padding: 12,
    width: 32,
    height:32,
    fontSize: 32,
  },
  dataContainer: {
    alignSelf: "stretch",
    flexGrow: 1,
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  metaDataContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderBottom: '1px solid #dddfe0',
  },
  secondaryText: {
    color: '#9299a2',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '12px',
  },
  primaryText: {
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '22px',
    color: '#333'
  },
  primarySmallText: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '22px',
    color: '#333'
  },
  brandLogo: {
    height: 12,
    marginLeft: 6,
  }
})

const mapStateToProps = ({permissions}) => ({
  canCancelPayment: checker.canCancelPayment(permissions)
})

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps)
)

export default enhance(PaymentItem)
