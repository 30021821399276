const formatter = (afterComa) =>
  new Intl.NumberFormat('ru-RU',{
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: afterComa,
    minimumFractionDigits: 0
  })


export default (amount, afterComa=2) =>{
  let realAmount = parseFloat(amount)
  if(isNaN(realAmount)) realAmount = 0.0
  return formatter(afterComa).format(realAmount)
}
