import React, {useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {axiosInstance} from '../api'
import LinearProgress from '@material-ui/core/LinearProgress'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IntegrationItem from '../components/integration-item';

const IntegrationsContainer = ({classes, shop, match, history}) => {
  const shopId = useSelector(state => state.shopId);
  const [integrations, setIntegrations] = useState(null);
  const loadIntegrations = async () => {
    const {data} = await axiosInstance.request({
      method: 'get',
      url: `/api/v1/account/shops/${shopId}/integrations`
    });

    setIntegrations(data);
  }

  useEffect(() => {
    loadIntegrations();
  }, []);

  const goToEdit = (integrationId) => {
    if (!integrationId) return;
    history.push(match.url + '/' + integrationId)
  }

  if (!integrations) {
    return (
      <div>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Typography component={'span'} className={classes.heading}>Интеграции с ERP системами</Typography>
      <Divider/>
      <div className={classes.integrationContainers}>
        {integrations.map(el => <IntegrationItem key={el.id} integration={el} goToEdit={goToEdit} />)}
      </div>
    </div>
  )
}


const inlineStyles = {
  container: {
    marginLeft: '10%',
    marginRight: '10%'
  }
}

const styles = (theme) => ({
  container:{
    padding: '16px 32px',
    backgroundColor: 'white'
  },
  heading: {
    fontSize: 22,
    padding: '12px 0',
    color: 'black',
    fontWeight: 300
  },
  integrationContainers: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

const enhance = compose(
  withStyles(styles)
)

export default enhance(IntegrationsContainer)
