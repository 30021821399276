import React from 'react'
import Dropzone from 'react-dropzone'
import blue from '@material-ui/core/colors/blue'
import { withStyles } from '@material-ui/core/styles'
import humps from 'humps'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import {STATUS_ON_REVIEW} from '../../shared/utils/global-values'
import { omit } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'

ACCEPTED_TYPES = ['	application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']

baseStyle = {
  borderWidth: 2,
  padding: '24px',
  borderColor: 'rgba(128, 128, 128, .4)',
  borderStyle: 'solid',
  borderRadius: 5,
};
overlayStyle = {
  backgroundColor: 'rgba(128, 128, 128, .5)',
  width: '100%'
  height: '100%'
  position:'absolute'
  display: 'flex'
  justifyContent:'center'
  alignItems: 'center'
  top: 0
}
activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};
rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

class OnPrepare extends React.Component

  constructor: ->
    super()
    @state =
     loading: {}

  onDrop: (key) => (files) =>
    file = files[0]
    body = new FormData()
    body.append(humps.decamelize(key), file)
    loading = {...@state.loading, [key]: true }
    @setState({loading})
    @props.updateBid(body)
          .then(() => @setState({loading: omit(@state.loading, [key])}))
          .catch(() => @setState({loading: omit(@state.loading, [key])}))

  renderDropzoneInner:(key) => (options) =>
    styles = {...baseStyle}
    styles = if options.isDragActive then {...styles, ...activeStyle} else styles
    styles = if options.isDragReject then {...styles, ...rejectStyle} else styles
    <div style={position:'relative'}>
    <div
      {...options.getRootProps()}
      style={styles}
    >
      <input {...options.getInputProps()} disabled={@state.loading[key]}/>
      <div>
        <p style={color: 'gray'}>
          Нажмите или перетащите файл сюда
        </p>
      </div>
      {options.isDragReject && <div>Неподдерживаемый тип</div>}
    </div>
    { @state.loading[key] &&
        <div style={overlayStyle}>
          <CircularProgress/>
        </div>
    }
    </div>

  keyToHref: (key) =>
    {legalType} = @props
    switch key
      when 'mainBid'
        "/documents/#{legalType}_bid.doc"
      else
        "/documents/#{humps.decamelize(key)}.doc"

  keyToLabel: (key) =>
    switch key
      when 'mainBid'
        "Основная анкета"
      when "offerAcceptanceBid"
        "Анкета принятия оферты"
      when "serviceBid"
        "Заявка на обслуживание"
      when 'authorityApproval'
        "Подтверждение полномочий руководителя"
      when 'associationApproval'
        "Устав с учредительным договором"
      when 'leaseContract'
        "Договор аренды"
      when 'passport'
        'Паспорт'
      else
        ""

  savedFile: (file) =>
    {filename, serviceUrl} = file
    <div style={display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4}>
      <Icon>folder_open</Icon>
      <a style={marginLeft: 12} className={@props.classes.link} href={serviceUrl}>{filename}</a>
    </div>

  renderDropzone:(key) =>
    {bid} = @props
    <div className={@props.classes.dropItem} key={key}>
      { bid[key]? && @savedFile(bid[key]) }
      <Dropzone accept={ACCEPTED_TYPES}
              style={{ position: 'relative' }}
              onDrop={@onDrop(key)}>
        {@renderDropzoneInner(key)}
      </Dropzone>
      <a className={@props.classes.link} href={@keyToHref(key)}>{@keyToLabel(key)}</a>
    </div>

  renderLegalityDropzone:(key) =>
    {bid} = @props
    <div className={@props.classes.dropItem} key={key}>
      { bid[key]? && @savedFile(bid[key]) }
      <Dropzone accept={ACCEPTED_TYPES}
              style={{ position: 'relative' }}
              onDrop={@onDrop(key)}>
        {@renderDropzoneInner(key)}
      </Dropzone>
      <span style={textAlign: 'center'}>{@keyToLabel(key)}</span>
    </div>

  legalityToDocuments: =>
    { legalType } = @props
    switch legalType
      when 'ie'
        ['passport']
      when 'entity'
        ['associationApproval', 'authorityApproval', 'leaseContract']
      else
        ['passport']

  allDocuments: =>
    { legalType, bid } = @props
    switch legalType
      when 'ie'
        bid.mainBid? && bid.offerAcceptanceBid? && bid.serviceBid? && bid.passport?
      when 'entity'
        bid.mainBid? && bid.offerAcceptanceBid? && bid.serviceBid? && bid.associationApproval? && bid.authorityApproval? && bid.leaseContract?
      else
        false

  changeStatus: =>
    @props.updateBid({status: STATUS_ON_REVIEW})

  render: ->
    { classes, bid, legalType } = @props
    <div>
      <div className={classes.root}>
        <h3 className={classes.heading}>Заявка</h3>
        <div className={classes.container}>
          {
            ["mainBid", 'offerAcceptanceBid', 'serviceBid'].map(@renderDropzone)
          }
        </div>
      </div>
      <div className={classes.root}>
        <h3 className={classes.heading}>Первичные документы</h3>
        <div className={classes.container}>
          {
            @legalityToDocuments().map(@renderLegalityDropzone)
          }
        </div>
      </div>
      <Button onClick={@changeStatus} variant="contained" color="primary" className={classes.button} disabled={!@allDocuments()}>
        Далее
      </Button>
    </div>


styles = (theme) => (
  root:
    display: 'flex'
    flexDirection: 'column'
    borderTop: '1px solid #dddfe0'
    padding: '12px 0px'
  heading:
    paddingLeft: 48
  container:
    display: 'flex'
    flexDirection: 'row'
    justifyContent: 'space-between'
  dropItem:
    padding: '12px 48px'
    display: 'flex',
    flexDirection: 'column'
  link:
    cursor: 'pointer'
    color: blue[300]
    fontSize: 14
    textAlign: 'center'
  button:
    margin: theme.spacing.unit
)

export default withStyles(styles)(OnPrepare)
