import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import fm from '../../shared/utils/format-money'
import { loadTotalsReconcilation  } from '../actions'
import { mapValues } from 'lodash'
import {decamelizeKeys} from 'humps'

class TotalReconcilation extends React.Component

  @defaultProps = {
    show: false,
    periodFrom: moment(),
    periodTo: moment()
  }

  constructor:(props) ->
    super(props)
    @state =
      reconcilation: null
      dialogOpened: props.show

  show: =>
    @setState({dialogOpened: true})

  componentDidUpdate: (prevProps, prevState) =>
    @setState({dialogOpened: @props.show}) if prevProps.show != @props.show
    @loadReconcilation() if prevProps.show != @props.show && @props.show

  loadReconcilation: =>
    {periodFrom, periodTo} = @props
    data = mapValues({periodFrom,periodTo}, (el) => el.format('YYYY-MM-DD'))
    console.log(data)
    @props.loadTotalsReconcilation(decamelizeKeys(data))
      .then(({data}) =>
        @setState({reconcilation: data})
      )

  closeModal: =>
    @setState({
      dialogOpened: false
      reconcilation: null
    })
    @props.onClose?()

  total: =>
    {reconcilation} = @state
    {periodFrom, periodTo} = @props
    total = reconcilation.totals.proceeds - reconcilation.totals.fee - reconcilation.totals.returns
    return fm(0) if total <= 0
    return "+ #{fm(total, 2)}" if total > 0
    fm(total, 2)

  fee: =>
    fee = @state.reconcilation.totals.fee
    return fm(0) if fee <= 0
    fm(fee, 2) if fee > 0

  heading: =>
    {periodFrom, periodTo} = @props
    return "Отчет за #{moment(periodFrom).format('DD.MM')}" if periodFrom.diff(periodTo, 'days') == 0
    "Отчет за #{moment(periodFrom).format('DD.MM')} - #{moment(periodTo).format('DD.MM')}"

  render: ->
    {classes} = @props
    {reconcilation, dialogOpened} = @state
    <Dialog open={dialogOpened} onClose={@closeModal}>
      <div className={classes.container}>
        {
          reconcilation &&
          <React.Fragment>
            <Typography component={'span'} className={classes.secondaryText}>{@heading()}</Typography>
            <Typography component={'span'} className={classes.heading}>{@total()}</Typography>
            <span style={margin: '6px 0'}>
              <span className={classes.secondaryText}>Сумма:</span>
              <span style={{marginLeft: 12}} className={classes.primarySmallText}>{fm(reconcilation.totals.proceeds, 2)}</span>
            </span>
            <span style={margin: '6px 0'}>
              <span className={classes.secondaryText}>в т.ч комиссия:</span>
              <span style={{marginLeft: 12}} className={classes.primarySmallText}>{@fee()}</span>
            </span>
            <span style={margin: '6px 0'}>
              <span className={classes.secondaryText}>Удержано по оп.возвр-та:</span>
              <span style={{marginLeft: 12}} className={classes.primarySmallText}>{fm(reconcilation.totals.returns, 2)}</span>
            </span>
          </React.Fragment>
        }
      </div>
    </Dialog>


styles = (theme) => (
  container:
    display: 'flex'
    flexDirection: 'column'
    alignItems: 'flex-start'
    width: '350px';
    padding: '16px';
  secondaryText:
    color: '#9299a2',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '12px',
  primaryText:
    fontWeight: 200,
    fontSize: 17,
    lineHeight: '22px',
    color: '#333'
  primarySmallText:
    fontWeight: 200,
    fontSize: 13,
    lineHeight: '22px',
    color: '#333'
  heading:
    fontSize: 24
    padding: '12px 0 0 0'
    color: 'black'
    fontWeight: 700
)

mapDispatchToProps = {
  loadTotalsReconcilation
}

enhance = compose(
 withStyles(styles),
 connect(null, mapDispatchToProps)
)

export default enhance(TotalReconcilation)
