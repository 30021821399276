import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux'
import trivialReduxMiddleware from 'trivial-redux-middleware'
import formatRequestMiddleware from './middlewares/FormatRequest'
import { composeWithDevTools } from 'redux-devtools-extension'
import 'moment/locale/ru'
import moment from 'moment'
import humps from 'humps'

moment.locale('ru')

const logger = (store) => (next) => (action) => {
  console.log(action);
  return next(action)
}

const middlewares = [
  formatRequestMiddleware,
  trivialReduxMiddleware
]

if (__DEV__) middlewares.push(logger)

const enhancers = [applyMiddleware(...middlewares)]

export default function setUpStore(api, initialState={}, additionalModules={}){
  const reducers = combineReducers({...additionalModules, ...api.reducers})
  const store = createStore(
    reducers,
    humps.camelizeKeys(initialState),
    composeWithDevTools(...enhancers)
  )
  return store
}
