import actionTypesFor from '../shared/utils/action-types-for'

export cancelPayment = (paymentId) ->
  types: actionTypesFor('cancel', 'payment')
  meta:
    fetch:
      url: "~/api/v1/account/payments/#{paymentId}/cancel"
      method: 'POST'

export loadTotalsReconcilation = (params) ->
  {
    types: actionTypesFor('totalsReconcilation', 'statistics'),
    meta: {
      fetch: {
        url: "~/api/v1/account/statistics/totals_reconcilation",
        params
      }
    }
  }
