import React from 'react'
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
import { withStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'
import api from '../api'
import {
  APPROVED
} from '../../shared/utils/global-values'
import Expand from 'react-expand-animated'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { connect } from 'react-redux'
import cn from 'classnames'
import fm from '../../shared/utils/format-money'
import TotalReconcilation from './TotalReconcilation'

class PaymentCharts extends React.Component

  @defaultProps =
    periodFrom: moment().startOf('month'),
    periodTo: moment()

  constructor: () ->
    super()
    @state =
      opened: false
      reconcilationOpened: false

  updateStats: =>
    @props.getStats({
      periodFrom: @props.periodFrom.format('YYYY-MM-DD'),
      periodTo: @props.periodTo.format('YYYY-MM-DD'),
      shopId: @props.shopId,
    })

  updateChart: =>
    return null unless @props.stats

    newData = {
      labels: @props.stats.chartData.labels,
      datasets: [
        { chartType: 'bar', name: 'Продажи', values: @props.stats.chartData.values || [] }
        { chartType: 'bar', name: 'Возвраты', values: @props.stats.chartData.refundValues || [] }
      ]
    }
    return @graph.update(newData) if @graph?
    @buildChart(newData)

  buildChart:(data) =>
    @graph = new Chart('#payment-charts', {
      type: 'axis-mixed'
      data: data
      height: 250
      axisOptions:
        xIsSeries: true,
        xAxisMode: 'tick'
      lineOptions:
        regionFill: 0
        dotSize: 3
      barOptions:
        spaceRatio: 0.4
        stacked: 0
      colors: [blue[500], purple[700]]
      tooltipOptions:
        formatTooltipX: (d) => moment(d).format('DD MMM')
        formatTooltipY: (d) => d + ' руб'
    })

  componentDidMount: ->
    @updateStats()

  periodChanged:(prevProps) =>
    @props.periodFrom != prevProps.periodFrom || @props.periodTo != prevProps.periodTo

  statsChanged: (prevProps) =>
    @props.stats != prevProps.stats

  shopChanged: (prevProps) =>
    @props.shopId != prevProps.shopId

  componentDidUpdate:(prevProps) ->
    @updateStats() if @periodChanged(prevProps) || @shopChanged(prevProps)
    @updateChart() if @statsChanged(prevProps)

  changeOpened: =>
    @setState({opened: !@state.opened})

  textForSpan: =>
    return "Показать графики" unless @state.opened
    'Скрыть графики'

  render: ->
    { classes, stats, theme } = @props
    console.log(theme);
    <div className={classes.container}>
      <TotalReconcilation periodFrom={@props.periodFrom} periodTo={@props.periodTo}
                          show={@state.reconcilationOpened} onClose={() => @setState({reconcilationOpened: false})}/>
      { stats? &&
        <div className={cn(classes.infoContainer)}>
          <div className={classes.infoItem}>
            <div style={color: 'rgba(0,0,0,.6)', fontSize: '13px'}>Выручка</div>
            <span style={fontWeight: 500, lineHeight: '38px', fontSize: 20}>{fm(stats.approvedTotalSum, 0)}</span>
          </div>
          <div className={classes.infoItem}>
            <div style={color: 'rgba(0,0,0,.6)', fontSize: '13px'}>Возвраты</div>
            <span style={fontWeight: 500, lineHeight: '38px', fontSize: 20}>{fm(stats.refundSum, 0)}</span>
          </div>
          <div className={classes.infoItem}>
            <span style={color: 'rgba(0,0,0,.6)', fontSize: '13px'}>Средний чек</span>
            <span style={fontWeight: 500, lineHeight:'38px', fontSize: 20}>{fm(stats.average, 0)}</span>
          </div>
          <div className={classes.infoItem}>
            <span style={color: 'rgba(0,0,0,.6)', fontSize: '13px'}>Количество платежей</span>
            <span style={fontWeight: 500, lineHeight: '38px', fontSize: 20}>{stats.paymentsCount}</span>
          </div>
        </div>
      }
      <div className={classes.columnContainer} onClick={@changeOpened}>
        <div className={classes.rowContainer}>
          <span className={classes.text}>{@textForSpan()}</span>
        </div>
      </div>
      <Expand open={@state.opened}>
        <div id='payment-charts' style={backgroundColor: 'white'}/>
      </Expand>
    </div>


styles = (theme) => (
  container:
    border: '1px solid #dddfe0'
    marginTop: 12
    display: 'flex'
    flexDirection: 'column'
    borderRadius: 4
    background: 'white'
  infoItem:
    display: 'flex'
    flexDirection: 'column'
    padding: '10px 20px'
    justifyContent: 'space-around'
    flex: 1
  infoContainer: {
    borderBottom: '1px solid #dddfe0'
    display: 'flex'
    alignItems: 'center'
    justifyContent:'space-between'
    "#{theme.breakpoints.down('sm')}": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    }
  },
  rowContainer:
    padding: '20px 0'
    display: 'flex'
    justifyContent: 'center'
  columnContainer:
    display: 'flex'
    borderBottom: '1px solid #dddfe0'
    cursor: 'pointer'
    flexDirection: 'column'
    '&:hover':{
      background: '#F6F7F8'
    }
    "#{theme.breakpoints.down('sm')}": {
      display: 'none'
    }
  text:
    color: blue[500]
    fontWeight: 200
    fontSize: 16
)

mapStateToProps = (state) => (
  stats: state.stats.data
)

mapDispatchToProps = {
  getStats: api.actions.stats.fetch
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentCharts))
