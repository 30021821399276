import React from 'react';
import { formatPhoneNumber} from "@/shared/utils/format-phone";
import TextField from "@material-ui/core/TextField";


export default class PhoneNumberInput extends React.Component {

    get value() {
        return formatPhoneNumber(this.props.value || '');
    }

    render() {
        return (
            <TextField
                {...this.props}
                value={this.value}
            />
        )
    }
}