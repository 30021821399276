import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import {NotificationContainer} from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../shared/mui-theme'
import ShopConnect from './containers/shop-connect'

import configureStore from './store';

OAuthApp = (props) =>
  <Provider store={configureStore(props.storeInitialState)}>
    <MuiThemeProvider theme={muiTheme}>
      <React.Fragment>
        <NotificationContainer/>
        <BrowserRouter>
          <Switch>
            <Route path='/oauth/connect_shop' component={ShopConnect}/>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    </MuiThemeProvider>
  </Provider>

export default OAuthApp
