import ReactOnRails from 'react-on-rails';

import LoginApp from '../bundles/Login';
import PaymentsApp from '../bundles/PaymentsApp'
import ShopApp from '../bundles/ShopApp'
import AccountApp from '../bundles/AccountApp'
import OAuthApp from '../bundles/OAuthApp'
import {CardInfo} from 'card-info'

CardInfo.setDefaultOptions({
  banksLogosPath: '/images/banks-logos/',
  brandsLogosPath: '/images/brands-logos/'
});

if(!__DEV__) console.log = function(){}

console.log(__DEV__)

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  LoginApp,
  PaymentsApp,
  ShopApp,
  AccountApp,
  OAuthApp
});
