import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography"

class Waiting extends React.Component

  render: ->
    { classes } = @props
    <div className={classes.root}>
      <Typography variant={"h3"}>Проверка заявки</Typography>
      <Typography variant={"body1"}>
        {@props.text}
      </Typography>
    </div>


styles = (theme) => (
  root:
    display: 'flex'
    flexDirection: 'column'
    borderTop: '0.5px solid #dddfe0'
    padding: theme.spacing(2)
    gap: theme.spacing(3)
)

export default withStyles(styles)(Waiting)
