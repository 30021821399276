import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import { connect } from 'react-redux'
import api from '../api';
import OmniPayLogo from '../assets/omni-pay-gradient.svg';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = ({palette, spacing, breakpoints}) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems:'center',
    padding: spacing(3),
    paddingRight: 8,
    boxShadow:'0 0 1px #bdbfc1, 0 1px #ced2d3',
    borderRadius: 12,
    background: 'white',
  },
  card: {
    boxShadow:'0 0 1px #bdbfc1, 0 1px #ced2d3',
    background: 'white',
    borderRadius: spacing(2),
    zIndex: 100,
  },
  menuItemHeader: {
    padding: `${spacing(1)}px ${spacing(2)}px`,
  },
  footerItem: {
    padding: `${spacing(1)}px ${spacing(2)}px`,
    color: "#747474",
  },
  logo: {
    height: 20,
    marginLeft: 12
  },
  ulList: {
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: 0
  },
  shopsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  liItem: {
    display: 'inline-block',
    marginLeft: 24,
    textAlign:'left'
  },
  nameSpan: {
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  firstLetter: {
    color: palette.primary.main,
    textTransform: 'capitalize',
  },
  mainName: {
    fontSize: 22,
    textTransform: 'capitalize',
    color: 'black',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  marginLink: {
    marginLeft: 18,
  },
  profileLink: {
    color: 'black',
    textTransform: 'none',
    cursor: 'pointer',
    transition: 'color .3s',
    '&:hover': {
      color: palette.primary.main,
    }
  }
})

class HeaderAppBar extends React.Component{

  userAnchor = React.createRef();

  shopsAnchor = React.createRef();

  state = {
    menuOpened: false,
    shopsOpened: false,
  }

  openPopper = () => this.setState({menuOpened: true})

  closePopper = () => this.setState({menuOpened: false})

  openShopsPopper = () => this.setState({shopsOpened: true})

  closeShopsPopper = () => this.setState({shopsOpened: false})

  logout = () => {
    this.props.logout().then(({data}) => window.location.assign(`${data.redirectUrl}`))
    this.closePopper()
  }

  goToAccountInfo = () => {
    const {accountId} = this.props
    window.location.assign(`/accounts/${accountId}`)
    this.closePopper()
  }

  goToShopInfo = () => {
    const {accountId, currentShopId} = this.props;
    window.location.assign(`/accounts/${accountId}/shops/${currentShopId}/info`)
  }

  goToMyInfo = () => {
    const {accountId} = this.props
    window.location.assign(`/accounts/${accountId}/me`)
    this.closePopper()
  }

  toMainScreen = () => {
    const {accountId} = this.props
    window.location.assign(`/accounts/${accountId}/payments`)
  }

  renderShopMenuItem = (shop) => {
    return (
        <MenuItem key={shop.id} onClick={this.changeShop(shop)}>
          {shop.title}
        </MenuItem>
    )
  }

  renderOtherShopsPopper = () => {
    const { classes } = this.props;
    const { shopsOpened } = this.state;
    return (
        <Popper open={shopsOpened} anchorEl={this.shopsAnchor.current} transition disablePortal style={{zIndex: 100}}>
          {({ TransitionProps, placement }) => (
              <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <div className={classes.card}>
                  <ClickAwayListener onClickAway={this.closeShopsPopper}>
                    <MenuList>
                      <div className={classes.menuItemHeader}>
                        <Typography variant={"h3"}>
                             <span className={classes.nameSpan}>
                               <span className={classes.firstLetter}>М</span>
                               агазины
                              </span>
                        </Typography>
                      </div>
                      {this.anotherShops.map(this.renderShopMenuItem)}
                    </MenuList>
                  </ClickAwayListener>
                </div>
              </Grow>
          )}
        </Popper>
    )
  }

  renderPopper = () => {
    const {menuOpened} = this.state
    const {classes, userName, accountId} = this.props
    return(
      <Popper open={menuOpened} anchorEl={this.userAnchor.current} transition disablePortal style={{zIndex: 100}}>
      {({ TransitionProps, placement }) => (
             <Grow
               {...TransitionProps}
               style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
             >
              <div className={classes.card}>
                <ClickAwayListener onClickAway={this.closePopper}>
                   <MenuList>
                     <div className={classes.menuItemHeader}>
                       <Typography variant={"h3"}>
                         <span className={classes.nameSpan}>
                          <span className={classes.firstLetter}>{userName.substr(0,1)}</span>
                           {userName.substr(1)}
                          </span>
                       </Typography>
                     </div>
                     <MenuItem onClick={this.goToAccountInfo}>Данные аккаунта</MenuItem>
                     { Boolean(this.currentShop) && <MenuItem onClick={this.goToShopInfo}>Настройка магазина</MenuItem> }
                     <MenuItem onClick={this.goToMyInfo}>Профиль</MenuItem>
                     <MenuItem onClick={this.logout}>Выход</MenuItem>
                     <Divider />
                     <div className={classes.footerItem}>
                       <Typography variant={"caption"}>
                         <span>ID Аккаунта: { accountId }</span>
                       </Typography>
                     </div>
                   </MenuList>
                </ClickAwayListener>
              </div>
             </Grow>
           )}
      </Popper>
    )
  }

  changeShop = (shop) => () => {
    this.closeShopsPopper();
    this.props.onShopChanged && this.props.onShopChanged(shop);
  }

  renderShop = (shop) => <MenuItem key={shop.id} onClick={this.changeShop(shop)}>{shop.title}</MenuItem>

  get currentShop() {
    const {shopsList, currentShopId} = this.props

    return shopsList.find((el) => el.id === currentShopId);
  }

  get anotherShops() {
    const {shopsList, currentShopId} = this.props
    return shopsList.filter((el) => el.id !== currentShopId)
  }

  render(){
    const { classes } = this.props

    return (
      <div style={{display: 'flex', justifyContent:'center', height: 68, width: '100%', marginTop: 12,}}>
      <div className={classes.root}>
          <a onClick={this.toMainScreen} style={{cursor: 'pointer', height: 18,}}>
            <img src={OmniPayLogo} alt={"Omni Pay"} />
          </a>
          <ul className={classes.ulList}/>
          { Boolean(this.currentShop) && Boolean(this.anotherShops.length) && (
              <IconButton ref={this.shopsAnchor} onClick={this.openShopsPopper}>
                <Icon fontSize={"small"}>storefront</Icon>
              </IconButton>
          )}
          <IconButton ref={this.userAnchor} onClick={this.openPopper}>
            <Icon fontSize={"small"}>menu</Icon>
          </IconButton>
          {this.renderPopper()}
          {this.renderOtherShopsPopper()}
      </div>
      </div>
    );
  }
}

HeaderAppBar.defaultProps = {
  shopsList: []
}

const mapStateToProps = (state) => ({
  accountId: state.accountId,
  userName: state.userName
})

const mapDispatchToProps = {
  logout: api.actions.sessions.destroy
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderAppBar))
