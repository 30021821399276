import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {compose} from 'recompose';

const IMAGE_SOURCES = {
  moysklad: '/images/moysklad-logo.svg'
}

const IntegrationItem = ({integration, classes, goToEdit}) => {
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={IMAGE_SOURCES[integration.handle]} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
         { integration.description }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={() => goToEdit(integration.id)}>
          Подробнее
        </Button>
      </CardActions>
    </Card>
  )
}

const styles = (theme) => ({
  root: {
    maxWidth: 345,
    margin: 12,
  },
  media: {
    height: 120,
    backgroundSize: 'contain',
    marginLeft: 24,
    marginRight: 24,
  },
});

const enhance = compose(
  withStyles(styles),
)

export default enhance(IntegrationItem);
