import baseEndpoints from '../../BaseApp/endpoints'

const FETCH = (entry) => (
  {
    type: 'fetch',
    entry
  }
)

export default {
  ...baseEndpoints,
  bids: '~/api/v1/account/bids',
  shops: '~/api/v1/account/shops',
  terminals: '~/api/v1/account/terminals',
  availableTerminalTypes: {type: 'setter'},
  currentBidId: {type: 'setter'},
  shopIsActive: {type: 'setter'},
  shopId: {type: 'setter'},
  account: {type: 'setter'},
  checkoutSettingsVisible: {type: 'setter'}
}
