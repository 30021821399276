export const READ_PERMISSION = 'read'
export const WRITE_PERMISSION = 'write'
export const CANCEL_PAYMENT_PERMISSION = 'cancel_payment'

export default class PermissionChecker {

  static canCancelPayment(obj){
    return (obj.payments || []).includes(CANCEL_PAYMENT_PERMISSION) || obj.admin
  }

  static canRead(app, obj) {
    return (obj[app] || []).includes(READ_PERMISSION) || obj.admin
  }

  static canWrite(app, obj) {
    return (obj[app] || []).includes(WRITE_PERMISSION) || obj.admin
  }
}
