import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import api from '../api'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import checker from '../../shared/utils/permissions'

styles = (theme) => (
  userItem:
    flex: 1,
    display: 'flex',
    alignItems: 'center'
    padding: '12px 0'
  userText:
    fontSize: 16
    color: 'black'
    fontWeight: 300
  heading:
    fontSize: 22
    fontWeight: 300
  collapseInner:
    padding: '0 12px'
)

class UserPermissionCard extends React.Component

  constructor:(props) ->
    super(props)
    @state =
      opened: false
      user: props.user

  deleteUser: =>
    @props.deleteUser(@props.user.id)
      .then(@props.userRefreshFunc)

  openPermissions: =>
    @setState(opened: !@state.opened)

  componentDidUpdate: (prevStat, prevProps) =>
    # if prevProps.user != @props.user
    #   console.log('СБРОООС')
    #   @setState(user: @props.user)

  permissionChange: (app) => (event) =>
    event.preventDefault()
    {user} = @state
    {permissions} = user
    {checked, value} = event.target
    newPermissions = {...permissions}
    newPermissions[app] = [] unless newPermissions[app]
    appPermissions = new Set(newPermissions[app])
    if checked
      appPermissions.add(value)
    else
      appPermissions.delete(value)
    newPermissions[app] = Array.from(appPermissions)

    newUser = {...user, permissions: newPermissions}

    @props.updateUser(newUser.id, newUser)
          .then(({data}) =>
            @setState(user: data)
          )

  adminPermissionChange: (event) =>
    {user} = @state

    @props.updateUser(user.id,
      permissions:
        admin: event.target.checked
    ).then(({data}) =>
      @setState(user: data)
    )

  paymentsForm: =>
    {user:{permissions}} = @state
    <FormControl component="fieldset">
        <FormLabel component="legend">Платежи</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={checker.canRead('payments', permissions)} value="read" onChange={@permissionChange('payments')} />
            }
            label="Чтение"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checker.canWrite('payments', permissions)} value="write" onChange={@permissionChange('payments')} />
            }
            label="Ред-ние"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checker.canCancelPayment(permissions)} value="cancel_payment" onChange={@permissionChange('payments')} />
            }
            label="Возвраты"
          />
        </FormGroup>
    </FormControl>

  shopsForm: =>
    {user: {permissions}} = @state
    <FormControl component="fieldset">
        <FormLabel component="legend">Магазины</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={checker.canRead('shops', permissions)} value="read"  onChange={@permissionChange('shops')} />
            }
            label="Чтение"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checker.canWrite('shops', permissions)} value="write"  onChange={@permissionChange('shops')} />
            }
            label="Ред-ние"
          />
        </FormGroup>
    </FormControl>

  accountsForm: =>
    {user:{permissions}} = @state
    <FormControl component="fieldset">
        <FormLabel component="legend">Аккаунт</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={checker.canRead('accounts', permissions)} value="read" onChange={@permissionChange('accounts')} />
            }
            label="Чтение"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checker.canWrite('accounts', permissions)} value="write" onChange={@permissionChange('accounts')} />
            }
            label="Ред-ние"
          />
        </FormGroup>
    </FormControl>

  adminForm: =>
    {user:{permissions}} = @state
    <FormControl component="fieldset">
        <FormLabel component="legend">Админ</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={permissions.admin} onChange={@adminPermissionChange}/>
            }
            label="Полный доступ"
          />
        </FormGroup>
    </FormControl>

  render: ->
    { classes, firstAuth } = @props
    { opened, user } = @state
    console.log(user)
    <div>
    <div className={classes.userItem}>
      <div style={flex:1}>
        <Typography component={'span'} className={classes.userText}>{user.name}({user.login})</Typography>
      </div>
      <IconButton onClick={@openPermissions}>
        <Icon>edit</Icon>
      </IconButton>
      <IconButton onClick={@deleteUser}>
        <Icon>delete_outline</Icon>
      </IconButton>
    </div>
    <Collapse in={opened} classes={wrapperInner: classes.collapseInner}>
      <Typography component={'p'} className={classes.heading}>Доступы</Typography>
      <div style={marginTop: 18}>
        {@adminForm()}
        {@paymentsForm()}
        {@shopsForm()}
        {@accountsForm()}
      </div>
    </Collapse>
    </div>

mapDispatchToProps = {
  deleteUser: api.requests.users.destroy,
  updateUser: api.requests.users.update
}

enhance = compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)

export default enhance(UserPermissionCard)
