import React from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

const CircleIcon = styled(Icon)`
  color: white;
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  background-color: #fe0000;
  border-radius: 40px;
`

const Container = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #F6F7F8;
`

const ResultHeader = styled.h1`
  padding: 0;
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 28px;
`

const ResultDescription = styled.p`
  margin: 0 0 20px;
  line-height: 20px;
`

const ContainerHeader = styled.div`
  margin: 30px 0 15px;
`

const NoAccessComponent = (props) => (
  <Container>
    <ContainerHeader>
      <CircleIcon>block</CircleIcon>
    </ContainerHeader>
    <ResultHeader>Нет доступа к этому разделу</ResultHeader>
    <ResultDescription>Попросите у владельца аккаунта дать вам доступ к определенным разделам</ResultDescription>
  </Container>
)

export default NoAccessComponent
