import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { axiosInstance } from '../api'
import LinearProgress from '@material-ui/core/LinearProgress'
import { compose } from 'recompose'
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {NotificationManager} from 'react-notifications'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import produce from "immer";
import CircularProgress from "@material-ui/core/CircularProgress";

const TERMINAL_MAPPING = {
    'Terminal::YandexPay': "Яндекс Пэй",
    'Terminal::Ubrr': "УБРИР",
    'Terminal::Invizo': "InWizo",
}

const CheckoutSettings = ({classes}) => {
    const shopId = useSelector(state => state.shopId);
    const [checkoutSettingsList, setCheckoutSettingsList] = useState([]);
    const [settingsForms, setSettingsForms] = useState({})
    const [fullTerminalList, setTerminalList] = useState([]);
    const [listLoading, setListLoading] = useState(true);

    const loadList = async () => {
        const { data } = await axiosInstance.request({
            method: 'GET',
            url: `/api/v1/account/shops/${shopId}/checkout_settings`
        });
        setCheckoutSettingsList(data);
        const form = {};
        for (const entry of data) {
            form[entry.id] = { terminalIds: entry.terminals.map((el) => el.uuid), updating: false }
        }
        setSettingsForms(form);
        console.log(form);
    }

    const loadTerminals = async () => {
        const { data } = await axiosInstance.request({
            method: 'GET',
            url: `/api/v1/account/terminals`
        });
        const filteredList = data.filter((el) => el.shopId === shopId);
        setTerminalList(filteredList);
    }

    const loadData = async () => {
        setListLoading(true);
        try {
            await Promise.all([
                loadTerminals(),
                loadList(),
            ])
        } catch(exc) {
            NotificationManager.error("Ошибка загрузки. Попробуйте позже")
        } finally {
            setListLoading(false);
        }
    }

    useEffect(() => {
        loadData()
    }, []);

    if (listLoading) {
        return (
            <div>
                <LinearProgress />
            </div>
        )
    }

    const humanizeTerminal = (type) => {
        return TERMINAL_MAPPING[type] || "Терминал"
    }

    const copyConfigurationUrl = (value) => () => {
        window.navigator.clipboard.writeText(value);
        NotificationManager.success("Значение скопировано")
    }

    const onTerminalsChange = (settingId) => (event) => {
        console.log(event.target.value);
        const newForm = produce(settingsForms, (draft) => {
            draft[settingId].terminalIds = event.target.value;
        });
        setSettingsForms(newForm);
    }

    const saveConfiguration = (settingId) => async (event) => {
        event.preventDefault();
        setSettingsForms(produce(settingsForms, (draft) => {
            draft[settingId].updating = true;
        }))
        try {
            const { data } = await axiosInstance.request({
                method: 'PUT',
                url: `/api/v1/account/shops/${shopId}/checkout_settings/${settingId}`,
                data: {
                    terminalIds: settingsForms[settingId].terminalIds,
                }
            });
            setSettingsForms(produce(settingsForms, (draft) => {
                draft[settingId].terminalIds = data.terminals.map((el) => el.uuid)
            }))
        } finally {
            setSettingsForms(produce(settingsForms, (draft) => {
                draft[settingId].updating = false;
            }))
        }
    }

    const terminalOptions = fullTerminalList.map((el) => ({value: el.uuid, label: `${humanizeTerminal(el.type)}(${el.merchantId})`}))

    const renderConfiguration = (setting) => {
        console.log("setting", setting,)
        const loading = settingsForms[setting.id].updating;
        return (
            <form key={setting.id} className={classes.configurationItem} onSubmit={saveConfiguration(setting.id)}>
                <Typography variant={"body"}>{ setting.default ? "Основной" : "Дополнительный" }</Typography>
                <TextField
                    value={setting.paymentUrl}
                    label="URL создания платежа"
                    name={"externalId"}
                    disabled
                    fullWidth
                    dense
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size={"small"} onClick={copyConfigurationUrl(setting.paymentUrl)}>
                                    <Icon>file_copy_outlined</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    name="terminalIds"
                    required
                    SelectProps={{
                        multiple: true,
                        value: settingsForms[setting.id] ? settingsForms[setting.id].terminalIds : [],
                        onChange: onTerminalsChange(setting.id)
                    }}
                    select
                    label="Способы оплаты"
                    fullWidth
                    dense
                    variant="outlined"
                >
                    {terminalOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <div>
                    <Button disabled={loading} variant={"contained"} color={"primary"} type={"submit"}>
                        { loading && <CircularProgress size={18} className={classes.progress} />}
                        Сохранить
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <Card className={classes.card}>
            <Typography variant={"h3"}>Настройки чекаута</Typography>
            <div className={classes.configurationContainer}>
                {checkoutSettingsList.map(renderConfiguration)}
            </div>
        </Card>
    )
}

const styles = (theme) => ({
    card: {
        padding: theme.spacing(3),
    },
    configurationContainer: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    configurationItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        borderColor: theme.palette.neutral.main,
        borderRadus: theme.spacing(1),
        padding: theme.spacing(2),
        borderWidth: 1,
        borderStyle: "solid"
    },
    progress: {
        marginRight: theme.spacing(1),
    }
});

const enhance = compose(
    withStyles(styles)
);

export default enhance(CheckoutSettings);