import axios from 'axios'
import {debounce} from 'lodash'

TOKEN = 'Token 9499454b9a69b197ec9163a00d53547d91a7a859'

export default class SuggestionService

  constructor:() ->
    @debouncedRequest = debounce(@request, 500, leading: true)

  cancel: ->
    # для отмены запросов

  request: (query) ->
    axios(
      method: 'POST'
      url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'
      headers:
        Authorization: TOKEN
      data:
        query: query
    )

  searchParty: (query) ->
    @cancel()
    cancel = new Promise((resolve) => @cancel = resolve)
    Promise.race([cancel, @request(query)])
