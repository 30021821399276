import React from 'react'
import Login from '../components/Login'
import Register from '../components/Register'
import ChangePassword from '../components/reset-password'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import {withStyles} from "@material-ui/core/styles";

class LoginAppContainer extends React.Component {

  render(){
    return(
      <BrowserRouter>
          <div className={this.props.classes.container}>
              <Switch>
                  <Route path="/login" component={Login} />
                  <Route path='/register' component={Register}/>
                  <Route path='/change_password' component={ChangePassword}/>
              </Switch>
          </div>
      </BrowserRouter>
    )
  }
}

const styles = (theme) => ({
    container: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: "auto",
        marginTop: theme.spacing(8),
        maxWidth: 440,
    }
});

export default withStyles(styles)(LoginAppContainer)
