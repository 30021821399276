import {
  DECLINED,
  APPROVED,
  CANCELED,
  CREATED,
  ON_PAYMENT,
  REFUNDED,
  REVERSED
} from './global-values'

export default function statusesToRequest(selectedFilter){
    switch(selectedFilter){
      case 'all':
        return {}
      case 'refund':
        return { status: [REFUNDED, REVERSED] }
      case 'declined':
        return { status: [CANCELED, DECLINED]}
      case 'paid':
        return { status: [APPROVED] }
      case 'wait':
        return { status: [CREATED, ON_PAYMENT]}
      default:
        return {}
    }
}
