import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import humps from 'humps'
import api from '../api'
import produce from 'immer'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import {compose} from 'recompose'
import { connect } from 'react-redux'

class ShopConnect extends React.Component

  constructor:(props) ->
    super(props)
    params = new URLSearchParams(props.location.search)
    @shopUUID = params.get('shop_uuid')
    @state =
      accountId: '',
      errors: {}

  componentDidMount: ->
    @props.getAccounts()

  handleChange: (name) => (event) =>
    newState = produce(@state, (draft) =>
      draft[name] = event.target.value
      return
    )
    @setState(newState)

  connectShop: (e) =>
    e.preventDefault()
    @props.connectShop(uuid: @shopUUID, accountId: @state.accountId)
          .then(({data}) =>
            console.log(data)
            window.location.assign(data.redirectUrl)
          )

  render: ->
    {classes, accounts=[]} = @props
    <div className={classes.container}>
      <form onSubmit={@connectShop}>
      <Card className={classes.card} raised={false}>
        <Typography component={'span'} className={classes.heading}>Подключение магазина к аккаунта</Typography>
        <TextField
              select
              variant="outlined"
              margin="normal"
              label="Выберите аккаунт"
              value={@state.accountId}
              onChange={@handleChange('accountId')}
        >
          {accounts.map((el) => <MenuItem key={el.id} value={el.id}>{el.title}</MenuItem>)}
        </TextField>
          <div>
            <Button type='submit' variant="contained" color="primary" className={classes.button}>
              Сохранить
            </Button>
          </div>
      </Card>
      </form>
    </div>


styles = (theme) =>
  container:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4rem'
  card:
    minWidth: 350,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px 32px'
  heading:
    fontSize: 22
    color: 'black'
    fontWeight: 300
    padding: '12px 0'
  button:
    marginTop: theme.spacing.unit
    marginBottom: theme.spacing.unit

mapDispatchToProps = {
  connectShop: api.actions.shopConnect.create,
  getAccounts: api.actions.accounts.index
}

mapStateToProps = (state) =>
  accounts: state.accounts.data.collection

enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)

export default enhance(ShopConnect)
