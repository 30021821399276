import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import api from '../api'
import {connect} from 'react-redux'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import {NotificationManager} from 'react-notifications'
import {omit, pick} from 'lodash'
import humps from 'humps'

class ResetPassword extends React.Component

  constructor:(props) ->
    super(props)
    @state =
      email: ''
      password: ''
      passwordConfirmation: ''
      token: @tokenParam()
      errors: {}

  handleChange: (name) => (event) =>
    @setState(
      [name]: event.target.value,
      errors: omit(@state.errors, [name])
    )

  getErrors: (errorKey) =>
    errors = @state.errors[errorKey]
    return '' unless errors
    return errors.join(', ') if errors instanceof Array
    errors

  tokenParam: =>
    params = new URLSearchParams(@props.location.search)
    params.get('token')

  hasToken: =>
    Boolean(@tokenParam())

  renderEmailForm: =>
    {classes} = @props
    <Card className={classes.card}>
      <TextField
        id="outlined-email-input"
        label="Email"
        error={!!@getErrors('email')}
        helperText={@getErrors('email')}
        onChange={@handleChange('email')}
        className={classes.textField}
        type="email"
        name="email"
        autoComplete="email"
        variant="outlined"
      />
      <Button type='submit' variant="contained" color="primary" className={classes.button}>
        Отправить
      </Button>
    </Card>

  renderPasswordForm: =>
    {classes} = @props
    <Card className={classes.card}>
      <TextField
        onChange={@handleChange('password')}
        error={!!@getErrors('password')}
        helperText={@getErrors('password')}
        label="Пароль"
        className={classes.textField}
        type="password"
        margin='normal'
        autoComplete="new-password"
        variant="outlined"
        />
      <TextField
        onChange={@handleChange('passwordConfirmation')}
        error={!!@getErrors('passwordConfirmation')}
        helperText={@getErrors('passwordConfirmation')}
        label="Повторите пароль"
        className={classes.textField}
        type="password"
        margin='normal'
        autoComplete="new-password"
        variant="outlined"
        />
      <Button type='submit' variant="contained" color="primary" className={classes.button}>
        Отправить
      </Button>
    </Card>

  changePassword: =>
    @props.resetPassword(pick(@state,['password', 'passwordConfirmation', 'token']))
        .then(() =>
          NotificationManager.success('Пароль обновлен')
          @props.history.replace('/login')
        )
        .catch(({response}) =>
          @setState({errors: humps.camelizeKeys(response.data)})
        )

  sendChangePasswordLink: =>
    @props.resetPassword(pick(@state, ['email']))
        .then(() =>
          NotificationManager.success('Ссылка на сброс пароля отправлена на эту почту')
        ).catch(({response}) =>
          @setState({errors: humps.camelizeKeys(response.data)})
        )

  proceedForm: (e) =>
    e.preventDefault()
    @sendChangePasswordLink() unless @hasToken()
    @changePassword() if @hasToken()

  render: ->
    { classes } = @props
    <div className={classes.container}>
      <form onSubmit={@proceedForm}>
        {
          if @hasToken() then @renderPasswordForm() else @renderEmailForm()
        }
      </form>
    </div>

styles = (theme) => (
  container:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4rem'
  textField:
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  card:
    minWidth: 350,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 16
  button:
    margin: theme.spacing.unit
)

mapDispatchToProps = {
  resetPassword: api.actions.resetPassword.create
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ResetPassword))
