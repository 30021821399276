import React from 'react'
import MainContainer from './main-container'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'

export default class AccountAppContainer extends React.Component

  render: ->
    <BrowserRouter>
      <Switch>
        <Route path='/accounts/:id' component={MainContainer}/>
      </Switch>
    </BrowserRouter>
