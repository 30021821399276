import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MenuItem from '@material-ui/core/MenuItem'
import api from '../api'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import produce from 'immer'
import {NotificationManager} from 'react-notifications'

class CreateDirectPayment extends React.Component

  @defaultProps = {
    show: false
  }

  constructor:(props) ->
    super(props)
    @state =
      errors: {}
      payment:
        amount: '0'
        description: ''
        externalNumber: ''
        email: ''
      dialogOpened: props.show

  show: =>
    @setState({dialogOpened: true})

  componentDidUpdate: (prevProps, prevState) =>
    @setState({dialogOpened: @props.show}) if prevProps.show != @props.show

  closeModal: =>
    @setState({
      dialogOpened: false
      payment:
        amount: '0'
        description: ''
        externalNumber: ''
        email: ''
    })
    @props.onClose?()

  fieldChanged: (name) => (event) =>
    value = event.target.value
    @setState(
      produce((draft) =>
        draft.payment[name] = value
        return
    ))

  createPayment:(e) =>
    e?.preventDefault()

    @props.createPayment({...@state.payment, shopId: @props.shopId})
      .then(({data}) =>
        NotificationManager.info("Платеж создан")
        @closeModal()
      )
      .catch((err) =>
        NotificationManager.error("Ошибка, возможно не все поля были заполнены")
      )

  render: ->
    return null if shops?.length == 0

    {classes, shops, shopId} = @props
    {payment, dialogOpened, errors} = @state
    <Dialog open={dialogOpened} onClose={@closeModal}>
      <form className={classes.container} onSubmit={@createPayment}>
        <TextField
            select
            variant="outlined"
            margin="normal"
            label="Магазин"
            disabled
            fullWidth
            value={shopId}
        >
            {
              shops.map((shop) =>
                <MenuItem key={shop.id} value={shop.id}>{shop.title}</MenuItem>
              )
            }
        </TextField>
        <TextField
            onChange={@fieldChanged('amount')}
            type='number'
            step='0.01'
            label={'Сумма оплаты*'}
            variant="outlined"
            value={payment.amount}
            fullWidth
            margin={'normal'}
        />
        <TextField
            onChange={@fieldChanged('description')}
            label={'Описание*'}
            multiline
            variant="outlined"
            value={payment.description}
            fullWidth
            margin={'normal'}
        />
        <TextField
            onChange={@fieldChanged('email')}
            label={'Почта клиента*'}
            type='email'
            variant="outlined"
            value={payment.email}
            fullWidth
            margin={'normal'}
        />
        <TextField
            onChange={@fieldChanged('externalNumber')}
            type='number'
            step='1'
            label={'Внешний номер заказа'}
            variant="outlined"
            value={payment.externalNumber}
            fullWidth
            margin={'normal'}
        />
        <div>
          <Button color='primary' variant='contained' type='submit'>
            Создать
          </Button>
        </div>
      </form>
    </Dialog>


styles = (theme) => (
  container:
    display: 'flex'
    flexDirection: 'column'
    alignItems: 'center'
    width: '350px';
    padding: '16px';
)

mapStateToProps = (state) => (
  shops: state.shops.data.collection,
)

mapDispatchToProps = {
  createPayment: api.actions.payments.create
  getShops: api.actions.shops.index
}

enhance = compose(
 connect(mapStateToProps, mapDispatchToProps, null, forwardRef: true),
 withStyles(styles)
)

export default enhance(CreateDirectPayment)
