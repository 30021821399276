import {cloneDeep} from 'lodash'

const isValidFetchingAction = (action) => action.types && action.meta && action.meta.fetch && action.meta.fetch.url

export default (store) => (next) => (action) => {
  if(!isValidFetchingAction(action)) return next(action)

  action = cloneDeep(action)

  let {url} = action.meta.fetch

  if(url[0] === '~') url = url.substr(1)
  action.meta.fetch.url = url

  const {token, accountId} = store.getState()

  if(!action.meta.fetch.headers) action.meta.fetch.headers = {}
  if (token) action.meta.fetch.headers['Authorization'] = token
  if (accountId) action.meta.fetch.headers['Account'] = accountId
  return next(action)
}
