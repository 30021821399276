import React from 'react'
import {connect} from 'react-redux'
import pt from 'prop-types'
import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { omit, pick } from 'lodash'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import humps from 'humps'
import api from '../api'
import produce from 'immer'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {compose} from 'recompose'
import UserPermissionCard from './UserPermissionCard'
import Collapse from '@material-ui/core/Collapse'

class UsersCard extends React.Component

  constructor: () ->
    super()
    @state =
      users: [],
      showNewUserForm: false,
      newUser: {
        email: ''
      }
      userPicked: null,
      errors: {}

  fetchUsers: =>
    @props.getUsers()
          .then(({data}) =>
            @setState(users: data)
          )

  addUser:(e) =>
    e.preventDefault()
    return @showForm() unless @state.showNewUserForm
    @props.addUser(@state.newUser)
      .then(() =>
        @setState({
            showNewUserForm: false,
            newUser: {
              email: ''
            }
          })
        @fetchUsers()
      )

  componentDidMount: ->
    @fetchUsers()

  renderLoader: =>
    <div>
      <LinearProgress />
    </div>

  handleChange: (name) => (event) =>
    newState = produce(@state, (draft) =>
      draft.newUser[name] = event.target.value
      return
    )
    @setState(newState)

  showForm: =>
    @setState({showNewUserForm: true})

  hideForm: =>
    @setState({showNewUserForm: false})

  renderUser:(user, index) =>
    <UserPermissionCard user={user} key={index} userRefreshFunc={@fetchUsers}/>

  creationForm: =>
    {newUser: {email, name}, showNewUserForm} = @state
    <Collapse in={showNewUserForm}>
      <TextField
        onChange={@handleChange('email')}
        label="Почта*"
        value={email || ''}
        fullWidth
        variant="outlined"
        margin="normal"
      />
    </Collapse>

  render: ->
    { classes, firstAuth } = @props

    console.log(@state.users)
    <div className={classes.wrapper}>
      <Card raised={false} className={classes.card}>
        <Typography component={'span'} className={classes.heading}>Пользователи</Typography>
        <Divider/>
        {@state.users.map(@renderUser)}
        {@creationForm()}
        <Button variant="contained" color="primary" className={classes.button} onClick={@addUser}>
          Добавить пользователя
        </Button>
      </Card>
    </div>


styles = (theme) => (
  wrapper:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '3rem',
  card:
    overflow: 'visible'
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px 32px'
  divider:
    height: theme.spacing.unit * 2
  textField:
    display: 'flex'
  button:
    marginTop: theme.spacing.unit
    marginBottom: theme.spacing.unit
  heading:
    fontSize: 22
    color: 'black'
    fontWeight: 300
    padding: '12px 0'
)

mapDispatchToProps = {
  addUser: api.requests.users.create
  getUsers: api.requests.users.index
}

mapStateToProps = (state) =>
  firstAuth: state.firstAuth

enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)

export default enhance(UsersCard)
