import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

class ConfirmationDialog extends React.Component
  constructor: ->
    super()
    @state = {
      open: false,
      description: '',
    }

  dismiss: =>
    @setState({ open: false })

  agree: =>
    @onAgree?()
    @dismiss()

  show:(description, onAgree) =>
    @setState({ open: true, description })
    @onAgree = onAgree

  render: ->
      <Dialog
          open={@state.open}
          onClose={@dismiss}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Вы уверены, что хотите сделать возврат?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {@state.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={@dismiss} color="primary">
            Нет
          </Button>
          <Button onClick={@agree} color="primary" autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>


export default ConfirmationDialog
