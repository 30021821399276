import React from 'react'
import {connect} from 'react-redux'
import pt from 'prop-types'
import classNames from 'classnames'
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import SuggestionService from '../suggestion-service'
import { omit, pick } from 'lodash'
import Button from '@material-ui/core/Button'
import humps from 'humps'
import api from '../api'
import produce from 'immer'
import LinearProgress from '@material-ui/core/LinearProgress'
import HeaderAppBar from '../../shared/components/HeaderBar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

styles = (theme) => (
  wrapper:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '3rem',
  card:
    overflow: 'visible'
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px 32px'
  container:
    position: 'relative'
  suggestionsContainerOpen:
    position: 'absolute'
    zIndex: 2
    marginTop: theme.spacing.unit
    left: 0
    right: 0
  suggestion:
    display: 'block'
    backgroundColor: 'white'
  suggestionsList:
    margin: 0
    padding: 0
    listStyleType: 'none'
  divider:
    height: theme.spacing.unit * 2
  textField:
    display: 'flex'
  button:
    marginTop: theme.spacing.unit
    marginBottom: theme.spacing.unit
  heading:
    fontSize: 22
    color: 'black'
    fontWeight: 300
    padding: '12px 0'
)


AutoCompleteInput = (inputProps) ->
   { classes, ...other } = inputProps
   <TextField
      margin="normal"
      variant="outlined"
      id="outlined-inn-input"
      {...other}
   />



SuggestionItem = (suggestion, { query, isHighlighted }) ->
   matches = match(suggestion.value, query)
   parts = parse(suggestion.value, matches)

   innMatches = match(suggestion.data.inn, query)
   innParts = parse(suggestion.data.inn, innMatches)

   <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          return (
            <span key={String(index)} style={ fontWeight: 500 }>
              {part.text}
            </span>
          ) if part.highlight
          <strong key={String(index)} style={ fontWeight: 300}>
            {part.text}
          </strong>
        )}
      </div>
      <div>
        {innParts.map((part, index) =>
          return (
            <span key={String(index)} style={ fontWeight: 500, fontSize: 12 }>
              {part.text}
            </span>
          ) if part.highlight
          <strong key={String(index)} style={ fontWeight: 300, fontSize: 12}>
            {part.text}
          </strong>
        )}
      </div>
    </MenuItem>


class AccountInfo extends React.Component

  constructor: () ->
    super()
    @state =
      account: null
      errors: {}
      suggestions: []

  componentDidMount: ->
    @service = new SuggestionService()
    @fetchAccount()

  fetchAccount: =>
    @props.getAccount(@accountId())
          .then(({data}) =>
            @setState(account: data)
          )

  accountId: => @props.match.params['id']

  saveAccountData:(e) =>
    e.preventDefault()
    @props.updateAccount(@accountId(), @state.account)
          .then(({data}) =>
            @setState(account: data)
            window.location.assign("/accounts/#{data.id}/payments")
          )
          .catch(({response}) => @setState({errors: response.data}))

  handleSuggestionsFetchRequested: ({value}) =>
    @service.searchParty(value)
            .then(({data}) =>
              data = humps.camelizeKeys(data)
              @setState({
                  suggestions: data.suggestions.slice(0,3)
              })
            )

  handleSuggestionsClearRequested: =>
    @setState(
      suggestions: []
    )

  handleChange: (name) => (event) =>
    newState = produce(@state, (draft) =>
      draft.account[name] = event.target.value
      return
    )
    @setState(newState)

  legalTypeMapper: (legalType) ->
    switch legalType
      when 'LEGAL' then 'entity'
      when 'INDIVIDUAL' then 'ie'
      else 'ie'

  getErrors: (errorKey) =>
    errors = @state.errors[errorKey]
    return '' unless errors
    return errors.join(', ') if errors instanceof Array
    errors

  onSuggestionSelected: (event, { suggestion }) =>
    @setState(produce(@state, (draft) =>
      draft.account.title = suggestion.unrestrictedValue
      draft.account.inn = suggestion.data.inn
      draft.account.legalType = @legalTypeMapper(suggestion.data.type)
      return
    ))

  renderLoader: =>
    <div>
      <LinearProgress />
    </div>

  render: ->
    { classes, firstAuth } = @props

    return @renderLoader() unless @state.account?

    {title, inn, legalType} = @state.account
    autosuggestProps = {
      renderInputComponent: AutoCompleteInput
      suggestions: @state.suggestions
      onSuggestionsFetchRequested: @handleSuggestionsFetchRequested
      onSuggestionsClearRequested: @handleSuggestionsClearRequested
      getSuggestionValue: (suggestion) => suggestion.value
      renderSuggestion: SuggestionItem
      onSuggestionSelected: @onSuggestionSelected
    }
    <div className={classes.wrapper}>
      <form onSubmit={@saveAccountData}>
        <Card className={classes.card} raised={false}>
          <Typography component={'span'} className={classes.heading}>Информация об аккаунте</Typography>
          <Divider/>
          <Autosuggest
            {...autosuggestProps}
            inputProps={
              label: 'Название организации(или ИНН)'
              value: title
              onChange: @handleChange('title')
              className: classes.textField,
              disabled: !firstAuth
            }
            theme={
              container: classes.container
              suggestionsContainerOpen: classes.suggestionsContainerOpen
              suggestionsList: classes.suggestionsList
              suggestion: classes.suggestion
            }
            renderSuggestionsContainer={(options) =>
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            }
          />
          <TextField
              onChange={@handleChange('inn')}
              label="ИНН"
              value={inn || ''}
              disabled={!firstAuth}
              fullWidth
              variant="outlined"
              margin="normal"
          />
          <TextField
              select
              disabled={!firstAuth}
              variant="outlined"
              margin="normal"
              label="Тип юр.лица"
              fullWidth
              value={legalType || ''}
              onChange={@handleChange('legalType')}
          >
              <MenuItem value={'ie'}>ИП</MenuItem>
              <MenuItem value={'entity'}>ООО</MenuItem>
          </TextField>
        {
          firstAuth &&
          <div>
            <Button type='submit' variant="contained" color="primary" className={classes.button}>
              Сохранить
            </Button>
          </div>
        }
        </Card>
      </form>
    </div>

mapDispatchToProps = {
  updateAccount: api.actions.accounts.update,
  getAccount: api.actions.accounts.show
}

mapStateToProps = (state) =>
  firstAuth: state.firstAuth

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountInfo))
