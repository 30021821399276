import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react'
import Rollbar from 'rollbar';

const rollbarConfig = {
    enabled: Boolean(process.env.ROLLBAR_TOKEN),
    accessToken: process.env.ROLLBAR_TOKEN || 'disabled',
    environment: process.env.NODE_ENV || 'development',
    captureUncaught: true,
    captureUnhandledRejections: true,
}

export const rollbarInstance = new Rollbar(rollbarConfig);

export default function RollbarBoundary(props) {
    return (
        <Provider instance={rollbarInstance}>
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
        </Provider>
    )
}