import React from 'react'
import HeaderAppBar from '../../shared/components/HeaderBar'
import AccountInfoContainer from './account-info'
import MyInfoCard from '../components/MyInfoCard'
import { Route } from 'react-router'
import {connect} from 'react-redux'
import checker from '../../shared/utils/permissions'

class MainContainer extends React.Component

  goToRoot: => window.location.assign("/accounts/#{this.props.accountId}/payments")

  render: ->
    <div style={inlineStyles.container}>
      <HeaderAppBar mainScreenLink={@goToRoot}/>
      <Route exact path='/accounts/:id' component={AccountInfoContainer}/>
      <Route exact path='/accounts/:id/me' component={MyInfoCard}/>
    </div>

inlineStyles = {
  container: {
    marginLeft: '10%',
    marginRight: '10%'
  }
}

mapStateToProps = (state) => ({
  accountId: state.accountId
})

export default connect(mapStateToProps)(MainContainer)
