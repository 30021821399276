import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import blue from '@material-ui/core/colors/blue'
import PaymentItem from './PaymentItem'
import InfiniteScroll from 'react-infinite-scroller'
import {debounce, pick, mapValues} from 'lodash'
import { connect } from 'react-redux'
import {cancelPayment} from '../actions'
import api from '../api'
import {
  REFUNDED,
  REVERSED
} from '../../shared/utils/global-values'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import {NotificationManager} from 'react-notifications'
import ConfirmationDialog from './ConfirmationDialog'
import sr from '../../shared/utils/statuses'
import produce from 'immer'
import { InlineDatePicker } from 'material-ui-pickers'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import CreateDirectPayment from './CreateDirectPayment'
import checker from '../../shared/utils/permissions'
import NoAccessComponent from '../../shared/components/no-access'
import Expand from 'react-expand-animated'
import MenuItem from "@material-ui/core/MenuItem";

class OperationsList extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      directPaymentCreation: false,
      showAdditionalFilters: false,
      currentPage: 0,
      totalPages: 1,
      payments: [],
      filters: {
        selectedFilter: 'all',
        periodFrom: moment().startOf('month'),
        periodTo: moment(),
        q: null,
        cardPan: null,
        shopId: props.shopId,
      }
    }
    this.nextPageFunction = debounce(this.loadNextPage, 500, {trailing: true})
  }

  componentDidMount(){
    this.loadPayments()
  }

  loadNextPage = () => {
    const {currentPage, totalPages} = this.state
    if(totalPages <= currentPage) return
    this.loadPayments(currentPage)
  }

  loadPayments = (currentPage=0) =>{
    const filters = this.requestBody()
    this.props.getPayments({page: currentPage + 1, ...filters})
              .then((res) => {
                const totalPages = parseInt(res.headers['xTotalPages']) || 1
                const payments = res.data
                this.setState({
                  currentPage: currentPage + 1,
                  totalPages,
                  payments: currentPage ? this.state.payments.concat(payments) : payments
                })
              })
  }

  cancelOrder = (paymentId) => () => {
    this.props.cancelPayment(paymentId)
        .then((res) => {
          if(res.data.status == REFUNDED) {
            NotificationManager.info(`Возврат по заказу №${paymentId} произведене. Деньги будут возвращены в течение 3-х дней.`)
          }
          if(res.data.status == REVERSED) {
            NotificationManager.info(`Реверс по заказу №${paymentId} произведене. Деньги уже у клиента.`)
          }
          const payments = this.state.payments.copyWithin()
          const index = payments.findIndex((el) => el.id === paymentId)
          payments.splice(index, 1, res.data)
          this.setState({payments})
        }).catch((err) => {
          NotificationManager.error(`Что-то пошло не так. Попробуйте попытку позже`)
        })
  }

  beforeCancelPayment = (paymentId) => () => {
    this.confirmation.show(
      'Возврат средств происходит в течение 3-х рабочих дней.',
      this.cancelOrder(paymentId)
    )
  }

  renderPayment = (payment, index) => {
    return (
      <div key={index}>
        <PaymentItem payment={payment} cancelOrder={this.beforeCancelPayment(payment.id)}/>
      </div>
    )
  }

  hasMore = () => {
    const {currentPage, totalPages} = this.state
    return totalPages > currentPage
  }

  requestBody = () => {
    const timeFilters = mapValues(pick(this.state.filters, ['periodFrom', 'periodTo']), (o) => o.format('YYYY-MM-DD'))
    const {selectedFilter, q, shopId, cardPan} = this.state.filters
    let filters = {...timeFilters, ...sr(selectedFilter), q, shopId, cardPan}
    return filters
  }

  updateShopId = async () => {
    this.setState(produce(this.state, (draft) => {
      draft.filters.shopId = e.target.value
    }))
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.filters.selectedFilter !== this.state.filters.selectedFilter ||
        prevState.filters.shopId !== this.state.filters.shopId ||
        prevState.filters.periodFrom !== this.state.filters.periodFrom ||
        prevState.filters.periodTo !== this.state.filters.periodTo
    ) {
      this.props.resetPayments()
      this.loadPayments()
    }

    if(prevProps.shopId !== this.props.shopId) {
      this.setState(produce(this.state, (draft) => {
        draft.filters.shopId = this.props.shopId
      }))
    }
  }

  changeFilter = (selectedFilter='all') => () => {
    this.setState(produce((draft) => {
      draft.filters.selectedFilter = selectedFilter
    }))
  }

  filterChanged = (field) => (e) => {
    this.setState(produce(this.state, (draft) => {
      draft.filters[field] = e.target.value
    }))
  }

  handleChangeStart = (periodFrom) => {
    this.setState(produce((draft) => {
      draft.filters.periodFrom = periodFrom.utcOffset(0, true)
    }))
    this.props.periodChanged({periodFrom})
  }

  handleChangeEnd = (periodTo) => {
    this.setState(produce((draft) => {
      draft.filters.periodTo = periodTo.utcOffset(0, true)
    }))
    this.props.periodChanged({periodTo})
  }

  get statusFilterOptions() {
    return [
      {
        value: 'all',
        label: 'Все'
      },
      {
        value: 'refund',
        label: 'Возвращенные'
      },
      {
        value: 'paid',
        label: 'Оплаченные'
      },
      {
        value: 'declined',
        label: 'Отмененные'
      },
      {
        value: 'wait',
        label: 'В ожидании'
      }
    ]
  }

  render(){
    const {classes, permissions} = this.props
    const {payments, filters: {periodFrom, periodTo, q, cardPan,selectedFilter}} = this.state
    return(
      <div className={classes.container}>
        <div style={{paddingBottom: '16px', background: 'white', borderBottom: '1px solid #dddfe0'}}>
            <form className={classes.filterColumn}
              onSubmit={(e) => {
                e.preventDefault()
                this.loadPayments()
              }}>
              <div style={{flex: 1, gap: 8, flexDirection: 'column', display: 'flex'}}>
                <div className={classes.expandTextContainer} onClick={() => this.setState({showAdditionalFilters: !this.state.showAdditionalFilters})}>
                  <span className={classes.expandText}>Показать все фильтры</span>
                </div>
                <div className={classes.filterColumnFlexity}>
                  <TextField placeholder='Поиск'
                             onChange={this.filterChanged('q')}
                             value={q || ''}
                             margin="dense"
                             variant="outlined"
                             InputProps={{
                               endAdornment: (
                                 <InputAdornment position="end">
                                  <Tooltip title="Поиск по номеру транзакции, почте клиента или телефону клиента">
                                   <IconButton>
                                     <Icon>help_outline</Icon>
                                   </IconButton>
                                  </Tooltip>
                                 </InputAdornment>
                               ),
                             }}/>
                  <InlineDatePicker
                      onlyCalendar
                      format={'DD.MM.YYYY'}
                      margin={'dense'}
                      variant="outlined"
                      label="С"
                      value={periodFrom}
                      onChange={this.handleChangeStart}
                  />
                  <InlineDatePicker
                      onlyCalendar
                      format={'DD.MM.YYYY'}
                      margin={'dense'}
                      variant="outlined"
                      label="До"
                      value={periodTo}
                      onChange={this.handleChangeEnd}
                  />
                  <div style={{flex: 1}} />
                  <Button type='submit' margin={'normal'} variant='contained' color='primary'>
                    Найти
                  </Button>
                </div>
                <Expand open={this.state.showAdditionalFilters}>
                  <div className={classes.filterColumnFlexity}>
                    <TextField
                      label='Номер карты'
                      onChange={this.filterChanged('cardPan')}
                      value={cardPan || ''}
                      margin="dense"
                      variant="outlined"
                    />
                  </div>
                </Expand>
                <div className={classes.filterColumnFlexity}>
                  <TextField
                      style={{minWidth: "240px"}}
                      value={selectedFilter}
                      name="filter"
                      select
                      label="Статус платежа"
                      dense
                      variant="outlined"
                      onChange={this.filterChanged('selectedFilter')}
                  >
                    {this.statusFilterOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                    ))}
                  </TextField>
                  <div style={{flex: 1}} />
                  {
                      !!this.props.shops.length && (
                          <Button margin={'normal'} variant='contained' color='primary'
                                  onClick={() => this.setState({directPaymentCreation: true})}>
                            Выставить оплату
                          </Button>
                      )
                  }
                </div>
              </div>
            </form>
        </div>
        <div>
          {!checker.canRead('payments', permissions) && <NoAccessComponent/>}
          <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.loadNextPage}
              hasMore={this.hasMore()}
          >
            {payments.map(this.renderPayment)}
          </InfiniteScroll>
        </div>
        <ConfirmationDialog ref={(ref) => this.confirmation = ref} description={'Возврат товара происходит в течение 3-х рабочих дней'}/>
        <CreateDirectPayment
          show={this.state.directPaymentCreation}
          onClose={() => this.setState({directPaymentCreation: false})}
          shopId={this.props.shopId}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container:{
    border: '1px solid #dddfe0',
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4
  },
  filterColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  filterColumnFlexity: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },
  badge: {
    backgroundColor: blue[500],
    fontWeight: 400,
    fontSize: 13,
    color: '#9299a2',
    padding: '3px 8px',
    marginLeft: 12,
    borderRadius: 40
  },
  filterTextStyle: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: 1.6,
    color: blue[500],
    transition: 'color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      color: '#333'
    }
  },
  selectedTextStyle: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: 1.6,
    color: '#333',
    transition: 'color 0.3s'
  },
  ulList: {
    flexGrow: 1,
    textAlign: 'left',
    paddingInlineStart: '0px'
  },
  liItem: {
    display: 'inline-block',
    marginLeft: 24,
    textAlign:'left'
  },
  liItemFirst: {
    display: 'inline-block',
    marginLeft: 0,
    textAlign:'left'
  },
  expandText: {
    color: blue[500],
    fontWeight: 200,
    fontSize: 16,
  },
  expandTextContainer: {
    display: 'flex',
    padding: '20px 0',
    borderBottom: '1px solid #dddfe0',
    cursor: 'pointer',
    justifyContent: 'center',
    '&:hover':{
      background: '#F6F7F8'
    }
  },
})

const mapStateToProps = state => ({
  shops: state.shops.data.collection,
  permissions: state.permissions
})

const mapDispatchToProps = {
  getPayments: api.actions.payments.index,
  resetPayments: api.actions.payments.reset,
  cancelPayment
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OperationsList))
