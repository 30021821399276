import React from 'react'
import HeaderAppBar from '../../shared/components/HeaderBar'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import BidContainer from './bid-container'
import InfoContainer from './info-container'
import IntegrationsContainer from './integrations-container';
import YandexPayContainer from "@/ShopApp/containers/yandex-pay-container"
import IntegrationCard from './integration-card';
import { withStyles } from '@material-ui/core/styles'
import { Card } from "@material-ui/core"
import { compose } from 'recompose'
import CheckoutSettings from "@/ShopApp/components/checkout-settings"

class MainContainer extends React.Component

  goTo:(route) => () =>
    {match} = @props
    @props.history.replace("#{match.url}/#{route}")

  liClassName: (key) =>
    {classes, location} = @props
    return classes.selectedTextStyle if location.pathname.includes(key)
    classes.filterTextStyle

  render: ->
    {classes, yandexPayIsAvailable, checkoutSettingsVisible} = @props
    <div style={inlineStyles.container}>
      <HeaderAppBar userName={@props.userName}/>
      <div className={classes.firstContainer}>
        <Card className={classes.container}>
          <ul className={classes.ulList}>
            <li className={classes.liItem} onClick={@goTo('info')}>
              <span className={@liClassName('/info')}>Данные магазина</span>
            </li>
            {yandexPayIsAvailable && (
            <li className={classes.liItem} onClick={@goTo('yandex-pay')}>
              <span className={@liClassName('/yandex-pay')}>Яндекс.Пэй</span>
            </li>
            )}
            <li className={classes.liItem} onClick={@goTo('bid')}>
              <span className={@liClassName('/bid')}>Заявка</span>
            </li>
            {
              checkoutSettingsVisible && (
                <li className={classes.liItem} onClick={@goTo('checkout-settings')}>
                  <span className={@liClassName('/checkout-settings')}>Чекаут</span>
                </li>
              )
            }
          </ul>
        </Card>
        <Card>
          <Switch>
            <Route path='/accounts/:id/shops/:shopId/bid' component={BidContainer}/>
            <Route path='/accounts/:id/shops/:shopId/info' component={InfoContainer}/>
            <Route path='/accounts/:id/shops/:shopId/integrations' exact component={IntegrationsContainer} />
            <Route path='/accounts/:id/shops/:shopId/integrations/:integrationId' component={IntegrationCard} />
            <Route path='/accounts/:id/shops/:shopId/yandex-pay' exact component={YandexPayContainer} />
            <Route path='/accounts/:id/shops/:shopId/checkout-settings' exact component={CheckoutSettings} />
          </Switch>
        </Card>
      </div>
    </div>


inlineStyles = {
  container: {
    margin: "auto",
    maxWidth: "1200px",
    padding: "0 16px",
  }
}

styles = (theme) =>

  firstContainer:
    marginTop: 12
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  container:
    padding: '6px 12px'
    display: 'flex'
    alignItems: 'center'
    borderBottom: '0.5px solid #dddfe0'
    backgroundColor: 'white'
  filterTextStyle:
    fontWeight: 300
    fontSize: 16
    lineHeight: 1.6
    color: theme.palette.primary.main,
    transition: 'color 0.3s'
    cursor: 'pointer'
    '&:hover':
      color: "#1A1A1A"
  selectedTextStyle:
    fontWeight: 300
    fontSize: 16
    lineHeight: 1.6
    color: '#333',
    transition: 'color 0.3s'
  ulList:
    fontFamily: 'Rubik !important',
    flexGrow: 1,
    textAlign: 'left',
    paddingInlineStart: '12px'
  liItem:
    display: 'inline-block',
    marginRight: '12px',
    textAlign:'left'

mapStateToProps = (state) =>
  userName: state.userName,
  accountId: state.accountId
  yandexPayIsAvailable: state.availableTerminalTypes.includes("Terminal::YandexPay")
  checkoutSettingsVisible: state.checkoutSettingsVisible

enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
)

export default enhance(MainContainer)
