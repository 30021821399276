import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import PhoneNumberInput from "@/shared/components/PhoneNumberInput";
import Button from "@material-ui/core/Button";
import produce from 'immer';
import { decamelizeKeys } from "humps";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

class YandexOnPrepare extends React.Component {
    constructor(props) {
        super(props);
        const form = {...props.bid};
        this.state = {
            form,
            loading: false,
        }
    }

    saveField = (event) => {
        this.setState(produce(this.state, (draft) => {
            draft.form[event.target.name] = event.target.value;
        }))
    }

    get revenueOptions() {
        return [
            {
                value: 'less_5_million',
                label: 'До 5 млн в месяц'
            },
            {
                value: 'from_5_to_30_million',
                label: 'От 5 до 30 млн в месяц'
            },
            {
                value: 'more_30_million',
                label: 'Более 30 млн в месяц'
            }
        ]
    }

    submitForm = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        await this.props.updateBid(decamelizeKeys(this.state.form));
        this.setState({ loading: false });
    }

    onUseOwnAccountClick = () => {
        this.props.updateBid({ has_own_account: true });
    }

    render() {
        const { classes, account } = this.props;
        const { form, loading } = this.state;

        return (
            <form className={classes.container} onSubmit={this.submitForm}>
                <Typography variant={"h3"}>Общая информация</Typography>
                <TextField
                    value={account.inn}
                    disabled
                    label="ИНН"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                />
                <TextField
                    value={account.title}
                    disabled
                    label="Наименование организации"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                />
                <TextField
                    value={form.shopName}
                    name="shopName"
                    label="Название магазина"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.shopUrl}
                    name="shopUrl"
                    label="URL Сайта"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.revenue}
                    name="revenue"
                    required
                    select
                    label="Прогноз оборота по операциям"
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                >
                    {this.revenueOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Typography variant={"h3"}>Платежная информация</Typography>
                <TextField
                    value={form.bankBik}
                    name="bankBik"
                    label="БИК"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.bankAccountNumber}
                    name="bankAccountNumber"
                    label="Расчетный счет"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <Typography variant={"h3"}>Данные подписанта</Typography>
                <TextField
                    value={form.ownerFullName}
                    name={"ownerFullName"}
                    label="ФИО"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <PhoneNumberInput
                    value={form.ownerPhone}
                    label="Телефон"
                    name={"ownerPhone"}
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.ownerEmail}
                    name={"ownerEmail"}
                    label="E-mail"
                    required
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <Typography variant={"h3"}>Даполнительные контакты</Typography>
                <TextField
                    value={form.contactFullName}
                    name={"contactFullName"}
                    label="ФИО"
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.contactPhone}
                    label="Телефон"
                    name={"contactPhone"}
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <TextField
                    value={form.contactEmail}
                    name={"contactEmail"}
                    label="E-mail"
                    fullWidth
                    dense
                    variant="outlined"
                    onChange={this.saveField}
                />
                <div className={classes.buttonContainer}>
                    <Button disabled={loading} type="submit" variant="contained" color="primary" className={classes.button}>
                        { loading && <CircularProgress size={18} className={classes.progress} />}
                        Отправить
                    </Button>
                    <Button disabled={loading} color={"primary"} onClick={this.onUseOwnAccountClick}>
                        У меня есть аккаунт в Я.Пэй
                    </Button>
                </div>
            </form>
        )
    }
}

const styles = (theme) => ({
    container: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    button: {
        maxWidth: "154px"
    },
    progress: {
        marginRight: theme.spacing(1),
    }
});

const mapStateToProps = (state) => ({
    account: state.account,
})

export default connect(mapStateToProps)(withStyles(styles)(YandexOnPrepare));