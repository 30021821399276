import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import cnames from "classnames";


class YandexPayBid extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <Typography variant={"h3"}>Этапы подключения</Typography>
                <div className={classes.stepsContainer}>
                    <div className={classes.stepItem}>
                        <div className={cnames(classes.stepCircle, classes.afterStepCircle)}>
                            <Typography variant={"h2"}>1</Typography>
                        </div>
                        <div className={classes.stepContent}>
                            <Typography variant={"h3"}>Регистрация<br/>в Яндекс Пэй</Typography>
                            <Typography variant={"body"}>
                                Авторизуйтесь в Яндекс под любой почтой, перейдите по <a href={"https://console.pay.yandex.ru/registration"} target={"_blank"}>ссылке регистрации</a> и заполните форму.
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.stepItem}>
                        <div className={cnames(classes.stepCircle, classes.afterStepCircle)}>
                            <Typography variant={"h2"}>2</Typography>
                        </div>
                        <div className={classes.stepContent}>
                            <Typography variant={"h3"}>Проверка<br/>партнера</Typography>
                            <Typography variant={"body"}>
                                Сотрудники Яндекса запросят всю необходимую информацию и отправят ее на проверку.
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.stepItem}>
                        <div className={cnames(classes.stepCircle, classes.afterStepCircle)}>
                            <Typography variant={"h2"}>3</Typography>
                        </div>
                        <div className={classes.stepContent}>
                            <Typography variant={"h3"}>Настройка<br/>интеграции</Typography>
                            <Typography variant={"body"}>
                                После проверки, можно приступить к интеграции, <a href={"https://omni-pay.ru/ya-tilda"} target={"_blank"}>подробная инструкция</a>.
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.stepItem}>
                        <div className={classes.stepCircle}>
                            <Typography variant={"h2"}>4</Typography>
                        </div>
                        <div className={classes.stepContent}>
                            <Typography variant={"h3"}>Начало приема<br/>платежей</Typography>
                            <Typography variant={"body"}>
                                После завершения интеграции и проведения тестовых платежей, можно начинать принимать оплату.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

const styles = (theme) => ({
    card: {
        padding: theme.spacing(3),
    },
    stepsContainer: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    stepItem: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        gap: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "row",
        }
    },
    stepContent: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
    },
    stepCircle: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 60,
        height: 60,
        borderRadius: 30,
        color: 'white',
        backgroundColor: '#1A1A1A'
    },
    afterStepCircle: {
        "&:after": {
            content: `""`,
            left: 60,
            width: "calc(100% - 36px)",
            position: 'absolute',
            height: 2,
            backgroundColor: theme.palette.primary.light,
            [theme.breakpoints.down('sm')]: {
                height: "calc(100% - 36px)",
                left: 30,
                top: 60,
                width: 2,
            }
        },
    }
});


export default withStyles(styles)(YandexPayBid);