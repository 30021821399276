import React from 'react';
import { Provider } from 'react-redux';
import Container from './containers'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import {NotificationContainer} from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../shared/mui-theme'

import configureStore from './store';
import {setAuthorizationData} from '../BaseApp/api';
import RollbarBoundary from "@/shared/components/rollbar-boundary";

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
class ShopApp extends React.Component {
  constructor(props) {
    super(props);
    setAuthorizationData(props.storeInitialState.token, props.storeInitialState.account_id);
  }

  render() {
    return (
        <RollbarBoundary>
          <Provider store={configureStore(this.props.storeInitialState)}>
            <MuiThemeProvider theme={muiTheme}>
              <React.Fragment>
                <NotificationContainer/>
                <BrowserRouter>
                  <Switch>
                    <Route path='/accounts/:id/shops/:shopId' component={Container}/>
                  </Switch>
                </BrowserRouter>
              </React.Fragment>
            </MuiThemeProvider>
          </Provider>
        </RollbarBoundary>
    )
  }
}

export default ShopApp;
