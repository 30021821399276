import React, {useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import { useSelector } from 'react-redux'
import {axiosInstance} from '../api'
import cnames from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'
import { compose } from 'recompose'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import produce from 'immer'
import Divider from '@material-ui/core/Divider'
import {set, get} from 'lodash';
import {NotificationManager} from 'react-notifications'

const IntegrationCard = ({classes, shop, match, history}) => {
  const integrationId = match.params.integrationId;

  const shopId = useSelector(state => state.shopId);
  const [integration, setIntegration] = useState(null);
  const [errors, setErrors] = useState({});

  const loadIntegration = async () => {
    const {data} = await axiosInstance.request({
      method: 'get',
      url: `/api/v1/account/shops/${shopId}/integrations/${integrationId}`
    });

    setIntegration(data);
  }

  const updateIntegration = async () => {
    const {data} = await axiosInstance.request({
      method: 'post',
      url: `/api/v1/account/shops/${shopId}/integrations/${integrationId}`,
      data: integration
    });
    NotificationManager.success("Данные интеграции обновлены")
    setIntegration(data);
  }

  useEffect(() => {
    loadIntegration();
  }, []);

  if (!integration) {
    return (
      <div>
        <LinearProgress />
      </div>
    )
  }

  const handleChange = (path) => (event) => {
    setIntegration(produce(integration, (draft) => {
      set(draft, path, event.target.value);
    }))
  }

  const handleBoolChange = (path) => (event) => {
    setIntegration(produce(integration, (draft) => {
      set(draft, path, event.target.checked);
    }))
  }

  return (
    <div className={classes.container}>
      <Box display="flex" px={2}>
        <IconButton onClick={() => history.replace(match.url.split('/').slice(0, -1).join('/'))}>
          <Icon fontSize="small">arrow_back</Icon>
        </IconButton>
        <Typography component={'h2'} className={classes.heading}>{integration.title}</Typography>
        <FormControlLabel
          value="active"
          onChange={handleBoolChange('active')}
          control={<Switch color='primary' checked={get(integration, 'active', false)} />}
          label={integration.active ? 'Включено' : 'Выключено'}
          labelPlacement="start"
        />
      </Box>
      <div style={inlineStyles.container}>
        <Typography component={'span'} className={classes.subheading}>Авторизация</Typography>
        <Divider/>
        <TextField
            onChange={handleChange('authorization.token')}
            label="Токен авторизации"
            value={get(integration, 'authorization.token', '')}
            fullWidth
            variant="outlined"
            margin="normal"
        />
        <Typography component={'span'} className={classes.subheading}>Настройки</Typography>
        <Divider/>
        <TextField
            onChange={handleChange('settings.numberPattern')}
            label="Шаблон номера заказа"
            value={get(integration, 'settings.numberPattern', '')}
            fullWidth
            variant="outlined"
            margin="normal"
        />
        <FormControlLabel
           control={
             <Checkbox
               checked={get(integration, 'settings.createInvoice', false)}
               onChange={handleBoolChange('settings.createInvoice')}
               name="createInvoice"
               color="primary"
             />
           }
           label="Автоматически создавать счет?"
         />
         <div>
           <Button onClick={updateIntegration} variant="contained" color="primary" className={classes.button}>
             Сохранить
           </Button>
         </div>
      </div>
    </div>
  )
}


const inlineStyles = {
  container: {
    padding: '0px 24px',
  }
}

const styles = (theme) => ({
  container:{
    padding: '16px 0px',
    backgroundColor: 'white'
  },
  subheading: {
    fontSize: 22,
    padding: '12px 0',
    color: 'black',
    fontWeight: 300
  },
  heading: {
    flex: 1,
    fontSize: 22,
    padding: '12px 0',
    color: 'black',
    fontWeight: 500
  },
  integrationContainers: {
    display: 'flex',
    flexWrap: 'wrap',
  }
})

const enhance = compose(
  withStyles(styles)
)

export default enhance(IntegrationCard)
