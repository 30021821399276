import api from '../api'

export loadFiscalizationServices = (shopId) ->
  {
    types: api.typesFor('index', 'externalServices'),
    meta: {
      fetch: {
        url: "~/api/v1/account/shops/#{shopId}/fiscalization"
      }
    }
  }

export getAuthFiscalizationUrl = (shopId) ->
  {
    types: api.typesFor('index', 'externalServices'),
    meta: {
      fetch: {
        url: "~/api/v1/account/shops/#{shopId}/fiscalization_oauth",
        method: 'POST'
      }
    }
  }
