import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import api from '../api'
import {connect} from 'react-redux'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import {omit, pickBy} from 'lodash'
import OmniPayLogo from '../../shared/assets/omni-pay-gradient.svg'


class RegisterContainer extends React.Component {

  constructor(props){
    super(props)
    const params = new URLSearchParams(props.location.search)
    this.insalesShop = pickBy({
      userId: params.get('user_id'),
      shop: params.get('shop'),
      insalesId: params.get('insales_id')
    })
    this.product = params.get('product')
    if(Object.keys(this.insalesShop).length !== 3) this.insalesShop = null
    this.state = {
      login: params.get('user_email') || '',
      errors: {}
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      errors: omit(this.state.errors, [name])
    })
  }

  tryLogin = (e) => {
    e.preventDefault()
    const {login} = this.state
    const data = {
      login,
      insalesShop: this.insalesShop,
      product: this.product,
    }
    this.props.register(data).then(this.loginInAccount).catch(this.handleErrors)
  }

  handleErrors = (err) => {
    if(err.response.status === 500) return
    this.setState({errors: {login: err.response.data.user_email[0]}})
  }

  loginInAccount = (res) => {
    const {accountId} = res.data
    window.location.assign(`/accounts/${accountId}/payments`)
  }

  goToLogin = () => {
    this.props.history.push(`/login${this.props.location.search}`)
  }

  render(){
    const { classes } = this.props;
    const {errors, login} = this.state
    return(
      <Card>
        <form onSubmit={this.tryLogin} className={classes.card}>
          <img src={OmniPayLogo} />
          <TextField
            error={!!errors.login}
            id="outlined-email-input"
            label="Email"
            onChange={this.handleChange('login')}
            value={login}
            className={classes.textField}
            helperText={errors.login}
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
          />
          <Button type='submit' variant="contained" color="primary" disableElevation className={classes.button}>
            Зарегистрироваться
          </Button>
          <Button onClick={this.goToLogin} variant={"text"} color={"secondary"}>
            У меня есть аккаунт
          </Button>
        </form>
      </Card>
    )
  }
}

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 16,
    borderRadius: 20,
  },
  button: {
    margin: theme.spacing.unit
  }
})

const mapDispatchToProps = {
  register: api.actions.register.create,
  accounts: api.actions.accounts.index
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(RegisterContainer))
