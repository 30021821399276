import baseEndpoints from '../../BaseApp/endpoints'


export default {
  ...baseEndpoints,
  accounts: '~/api/v1/accounts'
  sessions: '~/api/v1/sessions',
  users: '~/api/v1/account/users',
  me: '~/api/v1/account/me',
  firstAuth: {
    type: 'setter'
  }
}
