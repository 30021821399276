import React from 'react'
import AccountInfo from '../components/AccountInfo'
import UsersCard from '../components/UsersCard'
import MyInfoCard from '../components/MyInfoCard'
import { Route } from 'react-router'
import checker from '../../shared/utils/permissions'
import NoAccessComponent from '../../shared/components/no-access'
import {connect} from 'react-redux'

class AccountInfoContainer extends React.Component {

  render(){
    const {firstAuth, permissionWrite, permissionRead} = this.props
    return(
      <React.Fragment>
        {!permissionRead && <NoAccessComponent/>}
        {permissionRead &&
         (
           <React.Fragment>
              <AccountInfo match={this.props.match}/>
              { !firstAuth && permissionWrite && <UsersCard/> }
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  firstAuth: state.firstAuth,
  permissionWrite: checker.canWrite('accounts', state.permissions),
  permissionRead: checker.canRead('accounts', state.permissions)
})

export default connect(mapStateToProps)(AccountInfoContainer)
