import { createTheme } from '@material-ui/core/styles'

export default createTheme({
    palette: {
        primary: {
            main: '#6E21E6'
        },
        neutral: {
            main: "#F6F6F6"
        }
    }
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
                marginRight: 0
            }
            root: {
            }
        }
        MuiCard: {
            root: {
                borderRadius: '16px',
                boxShadow: "0px 8px 16px 0px #00000014",
            }
        }
        MuiTypography: {
            root: {
                fontFamily: 'Rubik !important',
            },
            h2: {
                fontWeight: '600',
                fontSize: 30,
                lineHeight: '32px',
            }
            h3: {
                fontWeight: '600',
                fontSize: 20,
                lineHeight: '23px',
            },
            caption: {
                fontWeight: '400',
                fontSize: 14,
                lineHeight: '17px',
            },
            body1: {
                fontWeight: '400',
                fontSize: 18,
                lineHeight: '21px',
            },
            colorSecondary: {
                color: '#B6B6B6'
            },
            colorPrimary: {
                color: '#1A1A1A'
            }
        },
        MuiInputBase: {
            root: {
                borderRadius: 16,
            },
            input: {
                padding: '12px 14px !important'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 14px) scale(1)",
            }
        }
        MuiButton: {
            root: {
                borderRadius: 16,
            },
            textSecondary: {
                color: '#858585',
                fontSize: 12,
                lineHeight: '14px'
                '&:hover': {
                    backgroundColor: 'transparent',
                }
            },
            raisedPrimary: {
                color: 'white'
            }
        }
    },
    typography: {
      useNextVariants: true,
    },
})
