import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import { connect } from 'react-redux'
import api from '../api'
import cnames from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import produce from 'immer'
import {NotificationManager} from 'react-notifications'
import {loadFiscalizationServices, getAuthFiscalizationUrl} from '../actions'
import TildaForm from "@/ShopApp/components/shop-form/tilda"
import { camelizeKeys } from 'humps';

AVAILABLE_FISCALIZATION = [{type: 'EKAM_ONLINE', title: 'ЕКАМ Онлайн'}]

SERVICE_NAME_MAPPER = {
  'EKAM_ONLINE': 'ЕКАМ Онлайн'
}

class InfoContainer extends React.Component

  constructor: ->
    super()
    @state =
      shop: null,
      errors: {}
      fiscalizationServices: []
      chosenService: null,
      loading: false,

  shopId: => @props.match.params['shopId']

  componentDidMount: ->
    @props.getShop(@shopId())
    @props.loadFiscalizationServices(@shopId())
      .then(({data}) => @setState(fiscalizationServices: data))

  updateShop: =>
    @setState({loading: true, errors: {}})
    @props.updateShop(@shopId(), @state.shop).then(({data}) =>
      @setState({shop:data, loading: false})
      NotificationManager.success("Данные магазина обновлены")
      @props.getShop(@shopId())
    )

  updateShopByObject: (shop) =>
    @setState({loading: true, errors: {}})
    @props.updateShop(@shopId(), shop).then(({data}) =>
      @setState({shop:data, loading: false})
      NotificationManager.success("Данные магазина обновлены")
      @props.getShop(@shopId())
    ).catch((exc) =>
      @setState({loading: false})
      console.log(exc.response)
      if exc.response
        @setState({ errors: camelizeKeys(exc.response.data || {}) })
      else
        NotificationManager.error("Ошибка сохранения")
    )

  componentDidUpdate:(prevProps) ->
    @setState(shop: @props.shop) if prevProps.shop != @props.shop && Boolean(@props.shop)

  addFiscalizationService: =>
    {chosenService} = @state
    @props.getAuthFiscalizationUrl(@shopId())
      .then(({data}) => window.location.assign(data.url))

  handleChange: (field) => (event) =>
    @setState(produce(@state, (draft) =>
      draft.shop[field] = event.target.value
      return
    ))

  chooseFiscalService:(event) =>
    @setState(produce(@state, (draft) =>
      draft.chosenService = event.target.value
      return
    ))

  renderInsalesShop: () =>
    { classes } = @props
    { shop } = @state
    (
      <React.Fragment>
        <Typography component={'span'} className={classes.heading}>Общая информация</Typography>
        <div style={padding: '12px 0px'}>
          <span className={classes.feeText}>Ставка {shop.fullFee.split('.')[0]}</span>
          <span className={classes.feeSmallText}>,{shop.fullFee.split('.')[1]} %</span>
        </div>
        <TextField
          onChange={@handleChange('title')}
          label="Название"
          value={shop.title || ''}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <div>
          <Button onClick={@updateShop} variant="contained" color="primary" className={classes.button}>
            Сохранить
          </Button>
          <Button href={shop.linkToShop} variant="outlined" color="primary" target='_blank'
                  className={cnames(classes.button, classes.buttonLeftMargin)}>
            В магазин
          </Button>
        </div>
      </React.Fragment>
    )

  render: ->
    { classes, legalType } = @props
    { shop, fiscalizationServices, chosenService, loading, errors } = @state
    return (
      <div>
        <LinearProgress/>
      </div>
    ) if !@state.shop
    <div>
      <div className={classes.container}>
        { shop.type is 'Shop::InsalesShop' && @renderInsalesShop() }
        { shop.type is 'Shop::TildaShop' && <TildaForm shop={shop} errors={errors} updating={loading} onSubmit={@updateShopByObject} />}
      </div>
    </div>


inlineStyles = {
  container: {
    marginLeft: '10%',
    marginRight: '10%'
  }
}

styles = (theme) => (
  container:
    padding: theme.spacing(3),
    backgroundColor: 'white'
  step:
    "& $completed": {
      color: blue[500]
    },
    "& $active": {
      color: blue[500]
    }
    "& $disabled": {
      color: 'rgba(0, 0, 0, 0.38)'
    }
  active:
    color: blue[500]
  completed:
    color: blue[500]
  disabled:
    color: 'rgba(0, 0, 0, 0.38)'
  button:
    marginTop: theme.spacing.unit
    marginBottom: theme.spacing.unit
  buttonLeftMargin:
    marginLeft: 2*theme.spacing.unit
  heading:
    fontSize: 22
    padding: '12px 0'
    color: 'black'
    fontWeight: 300
  feeText:
    fontWeight: 200,
    fontSize: 20,
    lineHeight: '22px',
    color: 'black'
  feeSmallText:
    fontWeight: 200,
    fontSize: 15,
    lineHeight: '22px',
    color: 'black'
  textInactive:
    color: 'gray'
    textDecoration: 'line-through'
  fiscalItem:
    flex: 1,
    display: 'flex',
    alignItems: 'center'
    padding: '12px 0'
  fiscalServiceText:
    fontSize: 16
    color: 'black'
    fontWeight: 300
)

mapStateToProps = (state) => ({
  shop: state.shops.data.current,
  legalType: state.accountLegalType
})

mapDispatchToProps = {
  getShop: api.actions.shops.show,
  updateShop: api.actions.shops.update,
  loadFiscalizationServices,
  getAuthFiscalizationUrl
}

enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
  withStyles(styles)
)

export default enhance(InfoContainer)
