import baseEndpoints from '../../BaseApp/endpoints'


export default {
  ...baseEndpoints,
  login: '~/api/v1/sessions'
  accounts: '~/api/v1/accounts'
  register: '~/api/v1/registrations'
  resetPassword: '~/api/v1/reset_password'
}
