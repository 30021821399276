import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './store';
import LoginContainer from './containers'
import {NotificationContainer} from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../shared/mui-theme'

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const LoginApp = (props) => (
  <Provider store={configureStore(props.storeInitialState)}>
    <MuiThemeProvider theme={muiTheme}>
      <React.Fragment>
        <NotificationContainer />
        <LoginContainer />
      </React.Fragment>
    </MuiThemeProvider>
  </Provider>
);

export default LoginApp;
