export const formatPhoneNumber = (rawNumber) => {
    const x = rawNumber.replace(/\D/g, '').match(/(\d{1})(\d{0,3})(\d{0,3})(\d{2})(\d{2})/);
    if (x) {
        const [_, internationalCode, areaCode, phonePart1, phonePart2, rest] = x;
        return internationalCode
            ? `+${internationalCode}` +
            (areaCode ? ' ' : '') +
            (!phonePart1 ? areaCode : '(' + areaCode + ') ') +
            phonePart1 +
            (phonePart2 ? '-' + phonePart2 : '') +
            rest || ''
            : '';
    }
    return rawNumber.replace(/[^\d/+]+/g, '');
}