import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import produce from "immer";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {NotificationManager} from 'react-notifications'
import api from '@/ShopApp/api'
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import {Grid, RadioGroup} from "@material-ui/core";
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";


class ConfigureYandexTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            testTerminalForm: props.terminal.testTerminal,
            prodTerminalForm: props.terminal,
            currentFormName: props.shopIsActive ? "prodTerminalForm" : "testTerminalForm",
            testTerminalFormErrors: {},
            prodTerminalFormErrors: {},
        }
    }

    saveField = (event) => {
        this.setState(produce(this.state, (draft) => {
            draft[draft.currentFormName][event.target.name] = event.target.value;
        }))
    }

    changeCurrentForm = (event) => {
        const { currentFormName } = this.state;
        if (currentFormName === 'testTerminalForm') {
            this.setState({ currentFormName: 'prodTerminalForm' });
        } else {
            this.setState({ currentFormName: 'testTerminalForm' });
        }
    }

    copyCallbackUrl = () => {
        window.navigator.clipboard.writeText(this.callbackUrl);
        NotificationManager.success("Адрес скопирован.")
    }

    getErrors = (errorKey) => {
        const { currentFormName } = this.state;
        let errors = this.state[`${currentFormName}Errors`][errorKey];
        if (!errors) return '';

        return errors.map((el) => el.full_message).join(', ')
    }

    get callbackUrl() {
        return `${window.location.origin}/yandex_pay`
    }

    get paymentMethodsOptions() {
        return [
            {
                id: 'all',
                value: ['card', 'split']
            },
            {
                id: 'split',
                value: ['split']
            }
        ]
    }

    get currentPaymentMethodOption() {
        const { currentFormName } = this.state;
        const form = this.state[currentFormName];
        return this.paymentMethodsOptions.find((el) => isEqual(el.value, form.availablePaymentMethods)) || {}
    }

    handlePaymentMethodChange = (event) => {
        const option = this.paymentMethodsOptions.find((el) => el.id === event.target.value);
        if (option) {
            this.setState(produce(this.state, (draft) => {
                draft[draft.currentFormName].availablePaymentMethods = option.value;
            }))
        }
    };

    submitForm = async (event) => {
        event.preventDefault();
        this.setState({ loading: true, [`${this.state.currentFormName}Errors`]: {}});

        try {
            const currentTerminal = this.state[this.state.currentFormName];
            await this.props.updateTerminal(
                currentTerminal.uuid,
                pick(currentTerminal, 'merchantId', 'yandexPayMerchantKey', 'availablePaymentMethods')
            );
        } catch(exc) {
            if (exc.response.data) {
                console.log(exc.response.data);
                this.setState({[`${this.state.currentFormName}Errors`]: exc.response.data})
            }
            console.error(exc);
        } finally {
            this.setState({ loading: false });
        }
    }

    renderProdKeyCallout() {
        const { classes } = this.props;
        return (
            <div className={classes.callout}>
                <Typography variant={"h5"}>💡</Typography>
                <Typography variant={"caption"}>
                    Убедитесь, что вы выпустили новый ключ в кабинете Яндекс.Пэй.
                    <br />
                    Правильный формат боевого ключа - <strong>b827b65cbb0d1502413f10abbb8ea725.ZfKUAgFUa9EuHvNcLHdvuThfFSSy9yNW</strong>
                </Typography>
            </div>
        )
    }

    renderTestKeyCallout() {
        const { classes } = this.props;
        return (
            <div className={classes.callout}>
                <Typography variant={"h5"}>💡</Typography>
                <Typography variant={"caption"}>
                    Ключ Yandex Pay Merchant API в тестовом режиме должен совпадать с Merchant ID
                </Typography>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { currentFormName, loading } = this.state;
        const form = this.state[currentFormName];
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerGroup}>
                        <Typography variant={"h3"}>Настройка Яндекс.Пэй</Typography>
                        {currentFormName === 'testTerminalForm' && (
                            <Chip className={classes.terminalChip} label={"Тестовый"} />
                        )}
                    </div>
                    <Typography variant={"caption"}>
                        <Grid component="label" container alignItems="center" spacing={0}>
                            <Grid item>
                                <Switch
                                    size={"small"}
                                    color={"primary"}
                                    name={"currentFormName"}
                                    checked={currentFormName === 'testTerminalForm'}
                                    onChange={this.changeCurrentForm}
                                />
                            </Grid>
                            <Grid item>Тестовая среда</Grid>
                        </Grid>
                    </Typography>
                </div>
                <form className={classes.form} onSubmit={this.submitForm}>
                    <TextField
                        value={form.merchantId || ''}
                        name={"merchantId"}
                        label="Merchant ID для магазина"
                        required
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <TextField
                        value={form.yandexPayMerchantKey || ''}
                        name={"yandexPayMerchantKey"}
                        label="Ключ Yandex Pay Merchant API"
                        error={!!this.getErrors('yandex_pay_merchant_key')}
                        helperText={this.getErrors('yandex_pay_merchant_key')}
                        required
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />

                    <TextField
                        value={this.callbackUrl}
                        name={"yandexCallbackUrl"}
                        label="Callback URL"
                        fullWidth
                        disabled
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size={"small"} onClick={this.copyCallbackUrl}>
                                        <Icon>file_copy_outlined</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl component="fieldset">
                        <FormLabel component="div">
                            <Typography variant={"caption"}>
                                Способы оплаты на сайте
                            </Typography>
                        </FormLabel>
                        <RadioGroup aria-label="availablePaymentMethods" name="availablePaymentMethods" value={this.currentPaymentMethodOption.id} onChange={this.handlePaymentMethodChange}>
                            <FormControlLabel value="all" control={<Radio color={"primary"} />} label="Яндекс Пэй со Сплитом" />
                            <FormControlLabel value="split" control={<Radio color={"primary"} />} label="Только оплата частями в Сплит" />
                        </RadioGroup>
                    </FormControl>

                    <Typography variant={"caption"}>
                        Скопируйте значения в форму из личного кабинета <a href={"https://console.pay.yandex.ru/settings"} target={"blank"}>Яндекс.Пэй</a>
                    </Typography>
                    {
                        !!this.getErrors('yandex_pay_merchant_key') && currentFormName === 'testTerminalForm' && this.renderTestKeyCallout()
                    }
                    {
                        !!this.getErrors('yandex_pay_merchant_key') && currentFormName === 'prodTerminalForm' && this.renderProdKeyCallout()
                    }
                    <div>
                        <Button disabled={loading} type="submit" variant="contained" color="primary" className={classes.button}>
                            { loading && <CircularProgress size={18} className={classes.progress} />}
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '0.5px solid #dddfe0',
        padding: theme.spacing(3),
        gap: theme.spacing(3),
    },
    form: {
        display: 'flex',
        flexDirection: "column",
        gap: theme.spacing(2)
    },
    progress: {
        marginRight: theme.spacing(1),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
    },
    headerGroup: {
        flex: 1,
        display: 'flex',
        alignItems: "center",
        gap: theme.spacing(2),
    },
    terminalChip: {
        backgroundColor: theme.palette.neutral.main,
        borderRadius: theme.spacing(1),
    },
    callout: {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.neutral.main,
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    }
});

const mapStateToProps = (state) => ({
    shopId: state.shopId,
    shopIsActive: state.shopIsActive,
})

const mapDispatchToProps = {
    updateTerminal: api.actions.terminals.update,
}

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
);

export default enhance(ConfigureYandexTerminal);