

export const CREATED = 'CREATED'
export const ON_PAYMENT = 'ON-PAYMENT'

export const ON_CHECKOUT = 'ON-CHECKOUT';
export const APPROVED = 'APPROVED'
export const DECLINED = 'DECLINED'
export const CANCELED = 'CANCELED'
export const REFUNDED = 'REFUNDED'
export const REVERSED = 'REVERSED'
export const EXPIRED = 'EXPIRED'
export const INTERNAL_ERROR = 'INTERNAL_ERROR'
export const ERROR = 'ERROR'

export const STATUS_ON_PREPARE = 'ON_PREPARE'
export const STATUS_ON_REVIEW = 'ON_REVIEW'
export const STATUS_ACCEPTED = 'ON_ACCEPTED'
export const STATUS_CERTIFICATES_WAITING = 'CERTS_WAITING'

export const BID_STATUSES = [STATUS_ON_PREPARE, STATUS_ON_REVIEW, STATUS_CERTIFICATES_WAITING, STATUS_ACCEPTED]


export const NAME_MAPPER = {
  'PRE_CREATED': 'Создается...',
  'ON-CHECKOUT': 'На чекауте',
  'CREATED': 'Создан',
  'APPROVED': 'Оплачен',
  'DECLINED': 'Отклонен банком',
  'CANCELED': 'Отменен покупателем',
  'REVERSED': 'Реверс',
  'REFUNDED': 'Возврат',
  'ON-PAYMENT': 'На оплате',
  'ON-LOCK': 'Средства зарезервированы',
  'EXPIRED': 'Сессия устарела',
  'INTERNAL_ERROR': 'Ошибка процессинга'
}
