import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import api from '../api'
import {connect} from 'react-redux'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import { omit, pickBy } from 'lodash'
import OmniPayLogo from '../../shared/assets/omni-pay-gradient.svg'

class LoginContainer extends React.Component

  constructor:(props) ->
    super(props)
    params = new URLSearchParams(props.location.search)
    @insalesShop = pickBy({
      userId: params.get('user_id'),
      shop: params.get('shop'),
      insalesId: params.get('insales_id')
    })
    @insalesShop = null if Object.keys(@insalesShop).length != 3
    @state =
      login: '',
      password: '',
      errors: {}

  handleChange: (name) => (event) =>
    @setState(
      [name]: event.target.value,
      errors: omit(this.state.errors, [name])
    )

  tryLogin: (e) =>
    e.preventDefault()
    { login, password } = @state
    data = {
      login,
      password,
      insalesShop: this.insalesShop
    }
    @props.login(data).then(@fetchAccounts).catch(({response}) => @setState({errors: response.data}))

  fetchAccounts: () =>
    @props.accounts().then((res) =>
      account = res.data[0]
      window.location.assign("/accounts/#{account.id}/payments")
    )

  getErrors: (errorKey) =>
    errors = @state.errors[errorKey]
    return '' unless errors
    return errors.join(', ') if errors instanceof Array
    errors

  goToRegister: =>
    @props.history.push("/register#{@props.location.search}")

  render: ->
    { classes } = @props
    <Card>
      <form onSubmit={@tryLogin} className={classes.card}>
        <img src={OmniPayLogo} />
        <TextField
          id="outlined-email-input"
          label="Email"
          error={!!@getErrors('login')}
          helperText={@getErrors('login')}
          onChange={@handleChange('login')}
          className={classes.textField}
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
        />
        <TextField
          onChange={@handleChange('password')}
          id="outlined-password-input"
          error={!!@getErrors('password')}
          helperText={@getErrors('password')}
          label="Пароль"
          className={classes.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
        />
        <div className={classes.buttonContainer}>
          <Button type='submit' variant="contained" color="primary" className={classes.button}>
            Войти
          </Button>
          <Button variant="outlined" color="primary" className={classes.button} onClick={@goToRegister}>
            Регистрация
          </Button>
        </div>
        <Button color="secondary" variant={"text"} className={classes.button} href='/change_password'>
          Восстановить пароль
        </Button>
      </form>
    </Card>

styles = (theme) => (
  textField:
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  buttonContainer:
    display: 'flex'
    gap: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  card:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 16
  button:
    flex: 1
)

mapDispatchToProps = {
  login: api.actions.login.create,
  accounts: api.actions.accounts.index
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginContainer))
