import React from 'react'
import {connect} from 'react-redux'
import pt from 'prop-types'
import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { omit, pick } from 'lodash'
import Button from '@material-ui/core/Button'
import humps from 'humps'
import api from '../api'
import produce from 'immer'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {compose} from 'recompose'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {NotificationManager} from 'react-notifications'

class MeInfoCard extends React.Component

  constructor: () ->
    super()
    @state =
      me: null,
      errors: {}

  fetchMe: =>
    @props.getMe()
          .then(({data}) =>
            @setState(me: data)
          )

  saveMe:(e) =>
    e.preventDefault()
    @props.updateMe(@state.me)
      .then(({data}) =>
        @setState(me: data)
        NotificationManager.info("Профиль обновлен")
        window.location.reload()
      )

  componentDidMount: ->
    @fetchMe()

  renderLoader: =>
    <div>
      <LinearProgress />
    </div>

  handleChange: (name) => (event) =>
    newState = produce(@state, (draft) =>
      draft.me[name] = event.target.value
      return
    )
    @setState(newState)

  handleChangeSettings: (name) => (value) =>
    newState = produce(@state, (draft) =>
      draft.me[name] = value
      return
    )
    @setState(newState)

  render: ->
    return @renderLoader() unless @state.me
    { classes } = @props
    console.log(@state)
    { me } = @state
    <div className={classes.wrapper}>
      <Card raised={false} className={classes.card}>
        <Typography component={'span'} className={classes.heading}>Мои данные</Typography>
        <Divider/>
        <TextField
          label="Почта"
          disabled
          value={me.login || ''}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          onChange={@handleChange('name')}
          label="Имя"
          value={me.name || ''}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={me.sendEmail}
                    onChange={(e) => this.handleChangeSettings('sendEmail')(e.target.checked)}
                    value="active"
                />
            }
            label="Получать уведомления"
        />
        <Button variant="contained" color="primary" className={classes.button} onClick={@saveMe}>
          Сохранить
        </Button>
      </Card>
    </div>


styles = (theme) => (
  wrapper:
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '3rem',
  userItem:
    flex: 1,
    display: 'flex',
    alignItems: 'center'
    padding: '12px 0'
  userText:
    fontSize: 16
    color: 'black'
    fontWeight: 300
  card:
    overflow: 'visible'
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px 32px'
  divider:
    height: theme.spacing.unit * 2
  textField:
    display: 'flex'
  button:
    marginTop: theme.spacing.unit
    marginBottom: theme.spacing.unit
  heading:
    fontSize: 22
    color: 'black'
    fontWeight: 300
    padding: '12px 0'
)

mapDispatchToProps = {
  getMe: api.requests.me.index
  updateMe: api.requests.me.create
}

mapStateToProps = (state) =>


enhance = compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)

export default enhance(MeInfoCard)
