import Button from '@material-ui/core/Button'
import blue from '@material-ui/core/colors/blue'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import cnames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  buttonRoot: {
    boxShadow: 'none',
    textTransform: 'none',
    color: theme.palette.getContrastText(blue['A700']),
    padding: '6px 12px',
    minWidth: 100,
    backgroundColor: blue['A700'],
    borderColor: blue['A700'],
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: blue['A400'],
      borderColor: blue['A400'],
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: blue['A400'],
      borderColor: blue['A400'],
    }
  },
  xl: {
    fontSize: 18
  },
  l:{
    fontSize: 16
  },
  m: {
    fontSize: 14
  },
  s: {
    fontSize: 12
  },
  progress: {
    color: 'white'
  }
})

class ColoredButton extends React.Component{

  buildClass(){
    const {classes, size} = this.props
    const names = [classes.buttonRoot]
    names.push(classes[size] || classes.m)
    return cnames(...names)
  }

  disabled = () => {
    const { loading, disabled } = this.props

    return disabled || loading
  }

  render(){
    const {loading, classes} = this.props
    return(
      <Button variant={this.props.variant} className={this.buildClass()}
              onClick={this.props.onClick} href={this.props.href} disabled={this.disabled()}>
        {!!loading && <CircularProgress size={18} className={classes.progress}/>}
        {!loading && this.props.placeholder}
      </Button>
    )
  }
}

export default withStyles(styles)(ColoredButton)
