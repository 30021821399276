import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import produce from "immer";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {NotificationManager} from "react-notifications";


class TildaForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            form: props.shop
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shop !== this.props.shop && Boolean(this.props.shop))
        this.setState({
            form: this.props.shop
        })
    }

    saveField = (event) => {
        this.setState(produce(this.state, (draft) => {
            draft.form[event.target.name] = event.target.value;
        }))
    }

    copyField = (name) => () => {
        window.navigator.clipboard.writeText(this.state.form[name]);
        NotificationManager.success("Значение скопировано")
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.form);
    }

    getErrors = (errorKey) => {
        const errors = this.props.errors[errorKey]
        if (!errors) return '';

        return errors.join(', ')
    }

    render() {
        const { classes, errors } = this.props;
        const { form } = this.state;
        return (
            <div className={classes.container}>
                <Typography variant={"h3"}>Настройки магазина</Typography>
                <form className={classes.form} onSubmit={this.onSubmit}>
                    <TextField
                        label="Название"
                        name={"title"}
                        value={form.title || ''}
                        error={!!this.getErrors("title")}
                        helperText={this.getErrors("title")}
                        fullWidth
                        dense
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <TextField
                        label="Адрес магазина"
                        name={"hostUrl"}
                        value={form.hostUrl || ''}
                        error={!!this.getErrors("hostUrl")}
                        helperText={this.getErrors("hostUrl")}
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <TextField
                        value={form.externalId || ''}
                        label="Логин платежной системы"
                        name={"externalId"}
                        disabled
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size={"small"} onClick={this.copyField('externalId')}>
                                        <Icon>file_copy_outlined</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        value={form.password || ''}
                        label="Секрет для подписи заказа"
                        name={"password"}
                        disabled
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size={"small"} onClick={this.copyField('password')}>
                                        <Icon>file_copy_outlined</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        value={form.successUrl || ''}
                        label="URL для перехода при успешной оплате"
                        name={"successUrl"}
                        error={!!this.getErrors("successUrl")}
                        helperText={this.getErrors("successUrl")}
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <TextField
                        value={form.failureUrl || ''}
                        label="URL для перехода при ошибке оплаты"
                        name={"failureUrl"}
                        error={!!this.getErrors("failureUrl")}
                        helperText={this.getErrors("failureUrl")}
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <TextField
                        value={form.notifyUrl || ''}
                        label="URL для уведомлений"
                        name={"notifyUrl"}
                        helperText={"Убедитесь, что он совпадает со значением из настроек Tilda"}
                        fullWidth
                        variant="outlined"
                        onChange={this.saveField}
                    />
                    <div>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    }
});

const enhance = compose(
    withStyles(styles)
)

export default enhance(TildaForm)