import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import PaymentsContainer from './payments-container'


export default class PaymentAppContainer extends React.Component {

    render(){
        return(
          <BrowserRouter>
            <Switch>
              <Route path="/accounts/:id/payments" component={PaymentsContainer} />
            </Switch>
          </BrowserRouter>
        )
    }
}
