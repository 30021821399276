import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { compose } from "recompose";
import api from "@/ShopApp/api";
import { connect } from "react-redux";
import ConfigureYandexTerminal from "@/ShopApp/components/configure-yandex-terminal";
import LinearProgress from "@material-ui/core/LinearProgress";

console.log(api.actions);

class YandexPayContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }
    componentDidMount() {
        this.loadTerminals()
    }

    async loadTerminals() {
        this.setState({ loading: true });
        try {
            await this.props.fetchTerminals();
        } catch(exc) {
            console.error(exc)
        } finally {
            this.setState({ loading: false });
        }
    }

    get yandexPayTerminal() {
        return this.props.terminals.find(el => el.type === 'Terminal::YandexPay' && el.shopId === this.props.shopId);
    }

    render() {
        const { loading } = this.state;
        if (loading) return (
            <div>
                <LinearProgress />
            </div>
        )

        return (
            <div>
                <div>
                    <ConfigureYandexTerminal terminal={this.yandexPayTerminal} />
                </div>
            </div>
        )
    }
}

const styles = (theme) => ({

});

const mapStateToProps = (state) => ({
    terminals: state.terminals.data.collection,
    shopId: state.shopId,
})

const mapDispatchToProps = {
    fetchTerminals: api.actions.terminals.index
}

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(YandexPayContainer);