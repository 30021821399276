
const SETTER = {
  type: 'setter'
}

export default {
  accountId: SETTER,
  token: SETTER,
  userId: SETTER,
  userName: SETTER,
  accountLegalType: SETTER,
  permissions: SETTER,
  sessions: '~/api/v1/sessions',
}
