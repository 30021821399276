import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './store';
import AccountContainer from './containers'
import {NotificationContainer} from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../shared/mui-theme'
import RollbarBoundary from "@/shared/components/rollbar-boundary";

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const AccountApp = (props) => (
    <RollbarBoundary>
      <Provider store={configureStore(props.storeInitialState)}>
        <MuiThemeProvider theme={muiTheme}>
          <React.Fragment>
            <NotificationContainer />
            <AccountContainer />
          </React.Fragment>
        </MuiThemeProvider>
      </Provider>
    </RollbarBoundary>
);

export default AccountApp;
